import { useEffect } from 'react';

/**
 *
 * @param ref is the button ref object which is used to open the dialog
 */

const useOutsideClick = (ref, func) => {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        func();
      }
    }
    // Bind the event listener
    window.addEventListener('click', handleClickOutside, true);
    return () => {
      // Unbind the event listener on clean up
      window.removeEventListener('click', handleClickOutside, true);
    };
  }, [ref, func]);
};

export default useOutsideClick;
