import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import assignApi from 'app/axios/api/assignApi';
import { TAssignQueryParams } from 'app/axios/api/types/assignTypes';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { ListAssignState, TListAssign } from './ProjectMemberTypes';

const initialState: ListAssignState = {
  listAssign: [],
  loading: false,
};

export const getListAssign = createAsyncThunk(
  'listMemberPage/getListAssign',
  async (params: TAssignQueryParams, thunkAPI) => {
    const data = await assignApi.get(params);

    return data.data.data as Array<TListAssign>;
  },
);

const slice = createSlice({
  name: 'listMemberPage',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getListAssign.pending, (state, action: PayloadAction<any>) => {
      state.loading = true;
    });
    builder.addCase(getListAssign.rejected, (state, action: PayloadAction<any>) => {
      state.loading = false;
    });
    builder.addCase(getListAssign.fulfilled, (state, action: PayloadAction<any>) => {
      state.listAssign = action.payload;
      state.loading = false;
    });
  },
});

export const { actions, reducer: memberListReducer } = slice;
// export const {  } = actions;
export default memberListReducer;
