import { axiosClient } from '../axiosClient';

const downloadFileAPI = {
  get: (pathFile: string) => {
    const url = `/api/file/${pathFile}`;
    return axiosClient.get<Blob>(url, {
      responseType: 'blob', // Set the responseType to 'blob' for handling binary data
      headers: {
        'Content-Type': 'application/octet-stream',
      },
    });
  },
  createComment: (data: FormData) => {
    const url = '/api/projectTaskAttachment';
    return axiosClient.post<FormData>(url, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
};

export default downloadFileAPI;
