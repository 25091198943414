import React from 'react';
import { map } from 'lodash';
import TickLink from './TickLink';

interface Props {
  className?: string;
  links: {
    link: string;
    label: string;
    isActive?: (match: boolean, location: { pathname: string }) => boolean;
  }[];
}
export default function TickLinkGroup({ links, className }: Props) {
  return (
    <div className={`flex flex-row ${className} border border-outline rounded-md overflow-hidden`}>
      {map(links, ({ link, label, isActive }) => (
        <TickLink {...{ link, label, isActive }} key={label} />
      ))}
    </div>
  );
}
