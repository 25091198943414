import c from 'clsx';
import React, { FC } from 'react';
import '../Icons.scss';
interface Props {
  className?: string;
  size?: number;
  title?: string;
  [x: string]: any;
}

const IconCheck: FC<Props> = (props) => {
  const { className, size = 18, title, ...rest } = props;
  return (
    <div className={c('icon-component', `size${size}`, className)} title={title} {...rest}>
      <svg
        width="19"
        height="18"
        viewBox="0 0 19 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.22131 11.9062L4.09381 8.77869L3.02881 9.83619L7.22131 14.0287L16.2213 5.02869L15.1638 3.97119L7.22131 11.9062Z"
          fill="black"
        />
      </svg>
    </div>
  );
};

export default IconCheck;
