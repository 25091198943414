import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { RootState } from 'types';
import { getLanguage, getLocalItem, saveLanguage } from 'utils/localStorage';
import './App.css';
import Notification from './components/Notification/Notification';
import { getCurrentUser, setAuth, setCognitoUserData } from './pages/Auth/slice/authSlice';
import RenderRoutes, { routes } from './routes/routes';
import './styles/main.scss';
import './styles/typography.scss';
import './styles/variables.scss';

const language = getLanguage();
saveLanguage(language);
moment.locale(language);

export function App() {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const { authenticated, currentUser, loading } = useSelector((state: RootState) => state.auth);
  const localToken = getLocalItem('token');
  const localRefreshToken = getLocalItem('refreshToken');

  const checkAuthLocal = !!(localToken && localRefreshToken);

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [i18n]);

  useEffect(() => {
    if (checkAuthLocal) {
      dispatch(setAuth());
    }
  }, [checkAuthLocal, dispatch]);

  useEffect(() => {
    if (authenticated) {
      dispatch(getCurrentUser());
      dispatch(setCognitoUserData());
    }
  }, [authenticated, dispatch]);

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Router basename="">
          <RenderRoutes
            routes={routes}
            checkAuthLocal={checkAuthLocal}
            currentUser={currentUser}
            loading={loading}
          />
        </Router>
        <Notification />
      </LocalizationProvider>
    </>
  );
}
