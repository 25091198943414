/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: 'ap-northeast-1',
  aws_cloud_logic_custom: [
    {
      name: 'authAPI',
      endpoint: 'https://tfvzq90ifj.execute-api.ap-northeast-1.amazonaws.com/production',
      region: 'ap-northeast-1',
    },
  ],
  Auth: {
    region: 'ap-northeast-1',
    userPoolId: 'ap-northeast-1_ULucfESSV',
    userPoolWebClientId: 'i4o126oo3hrqor40i5d61lm1i',
    oauth: {
      domain: 'xcare.auth.ap-northeast-1.amazoncognito.com',
      scope: ['email', 'profile', 'openid'],
      redirectSignIn: 'https://app.xcareplatform.com/auth',
      redirectSignOut: 'https://app.xcareplatform.com/auth',
      // redirectSignIn: 'https://xcare.inteamchat.com/signup',
      // redirectSignOut: 'https://xcare.inteamchat.com/signout',
      // redirectSignIn: 'http://localhost:3000/signup',
      // redirectSignOut: 'http://localhost:3000/signout',
      // redirectSignIn: 'http://localhost:3000/auth',
      // redirectSignOut: 'http://localhost:3000/auth',
      responseType: 'token',
    },
  },
};

export default awsmobile;

// const awsmobile = {
//   aws_project_region: process.env.REACT_APP_AWS_PROJECT_REGION ?? 'ap-northeast-1',
//   aws_cloud_logic_custom: [
//     {
//       name: process.env.REACT_APP_AWS_CLOUD_LOGIC_CUSTOM_NAME ?? 'authAPI',
//       endpoint:
//         process.env.REACT_APP_AWS_CLOUD_LOGIC_CUSTOM_ENDPOINT ??
//         'https://w3cpzfspye.execute-api.ap-northeast-1.amazonaws.com/local',
//       region: process.env.REACT_APP_AWS_PROJECT_REGION ?? 'ap-northeast-1',
//     },
//   ],
//   Auth: {
//     region: process.env.REACT_APP_AWS_PROJECT_REGION ?? 'ap-northeast-1',
//     userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID ?? 'ap-northeast-1_NJwrIxNuS',
//     userPoolWebClientId:
//       process.env.REACT_APP_COGNITO_CLIENT_ID ??
//       'ap-northeast-1:291edf1b-887b-409d-84e7-05cb6bbeab80',
//     oauth: {
//       domain:
//         process.env.REACT_APP_COGNITO_OAUTH_DOMAIN ??
//         'xcareuserpool.auth.ap-northeast-1.amazoncognito.com',
//       scope: ['email', 'profile', 'openid'],
//       redirectSignIn: process.env.REACT_APP_SNS_REDIRECT_URL ?? 'https://xcare.inteamchat.com/auth',
//       redirectSignOut:
//         process.env.REACT_APP_SNS_REDIRECT_URL ?? 'https://xcare.inteamchat.com/auth',
//       // redirectSignIn: 'https://xcare.inteamchat.com/signup',
//       // redirectSignOut: 'https://xcare.inteamchat.com/signout',
//       // redirectSignIn: 'http://localhost:3000/signup',
//       // redirectSignOut: 'http://localhost:3000/signout',
//       // redirectSignIn: 'http://localhost:3000/auth',
//       // redirectSignOut: 'http://localhost:3000/auth',
//       responseType: 'token',
//     },
//   },
// };

// export default awsmobile;
