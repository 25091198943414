import React, { ReactNode } from 'react';
import StorageContext from '../Context/LocalStorage';
import Header from './Header/Header';
import './Layout.scss';

export type PropsLayout = {
  children: ReactNode;
};

function Layout({ children }: PropsLayout) {
  return (
    <StorageContext>
      <div className="layout">
        <div className="header">
          <Header />
        </div>
        <div className="wrapper">{children}</div>
      </div>
    </StorageContext>
  );
}

export default Layout;
