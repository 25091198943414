import { axiosClient } from '../axiosClient';
import { TChangeEmail, TChangePassword, TSignUp, TSignUpExpert } from './types/authTypes';

const authApi = {
  signUp: (data: TSignUp) => {
    const url = '/auth/signup';
    return axiosClient.post(url, data);
  },
  signUpExpert: (data: TSignUpExpert) => {
    const url = '/auth/signup';
    return axiosClient.post(url, data);
  },
  changePassword: (data: TChangePassword) => {
    const url = '/auth/update';
    return axiosClient.put(url, data);
  },
  changeEmail: (data: TChangeEmail) => {
    const url = '/auth/updateEmail';
    return axiosClient.put(url, data);
  },
  signIn: () => {
    const url = 'auth/signin';
    return axiosClient.get(url);
  },
  generateInitialPassword: (data: any) => {
    const url = '/auth/generate-init-pass';
    return axiosClient.post(url, data);
  },
  verifyAuthID: (id: string) => {
    const url = `/auth/verify-auth-id/${id}`;
    return axiosClient.get(`${url}`);
  },
  sendUserInvitation: (userID: string) => {
    const url = `/auth/invite/${userID}`;
    return axiosClient.post(`${url}`);
  },
};

export default authApi;
