export const Epath = {
  // Home Page
  pathHomePage: '/',

  // Expert login
  pathLogin: '/login',

  // Expert sign up
  pathSignUp: '/signup',

  // Expert onboarding
  pathExpertOnboarding: '/expert-onboarding',

  // Expert sign up
  pathSignUpPassword: '/signup/set-password',

  // Expert forget password
  pathForgetPassword: '/forgot-password',

  // Expert reset password
  pathResetPassword: '/reset-password',

  // Expert setting password from invitation
  pathSettingPassword: '/setting-password',

  // Expert pre-entry form */
  pathPreEntry: '/pre-entry-form',

  // Expert change password
  pathChangePassword: '/account',

  // Expert resume
  pathResumePage: '/resume',

  // Expert operational management
  pathOpManagementPage: '/operational-management',

  //Expert inquiry
  pathInquiry: '/inquiry',

  //Expert Term of service
  pathTermOfService: '/term-of-service',

  //Expert Privacy policy
  pathPrivacyPolicy: '/privacy-policy',

  //Expert Cookie policy
  pathCookiePolicy: '/cookie-policy',

  // 404 Not found
  pathNotFoundPage: '/not-found',

  // xCare
  // xCare login
  pathXCareLogin: '/medispurt/login',

  // xCare expert list
  pathXCareExpertList: '/medispurt/expert',

  // xCare expert detail
  pathXCareExpertDetail: '/medispurt/expert/:id',

  // xCare expert detail
  pathXCareExpertEditDetail: '/medispurt/expert/edit/:id',

  pathXCareProjects: '/medispurt/projects',

  // xCare client layout
  pathXCareClientLayout: '/medispurt/clients',

  // xCare client layout
  pathXCareProjectLayout: '/medispurt/projects/:id',

  // xCare client list
  pathXCareClientList: '/medispurt/clients/ls',

  // xCare client detail
  pathXCareClientDetail: '/medispurt/clients/ls/detail/:id',

  // xCare client edit
  pathXCareClientEdit: '/medispurt/clients/ls/edit/:id',

  // xCare client job tag list
  pathXCareJobTag: '/medispurt/clients/jobtag',

  // xCare client list
  pathXCareProjectList: '/medispurt/clients/projects',

  // xCare project detail
  pathXCareClientProjectDetail: '/medispurt/clients/projects/detail/:id',
  // xCare project edit
  // xCare client project hearing
  pathXCareClientHearing: '/medispurt/clients/projects/detail/:id/hearing',
  // xCare client project quotation
  pathXCareClientQuotation: '/medispurt/clients/projects/detail/:id/quotation',
  // xCare client project quotation detail
  pathXCareClientQuotationDetail: '/medispurt/clients/projects/detail/:id/quotation/:quotationId',
  // xCare client project assignment
  pathXCareClientAssignment: '/medispurt/clients/projects/detail/:id/assignment',
  // xCare client project contract
  pathXcareClientContract: '/medispurt/clients/projects/detail/:id/contract/contract-edit',

  // xCare project detail
  pathXcareClientContractDetail: '/medispurt/clients/projects/detail/:id/contract',

  // xCare Project Management
  pathXCareProjectManagement: '/medispurt/projects',
  pathXCareProjectManagementList: '/medispurt/projects/list',
  // xCare Project Management list task
  pathXCareProjectTask: '/medispurt/projects/:id/project-task',
  // xCare Project Management task detaik
  pathXCareTaskDetail: '/medispurt/projects/:id/taskDetail/:taskId',
  // xCare Project Management Gantt
  pathXCareProjectGantt: '/medispurt/projects/:id/gantt-chart',
  // xCare Project Memo
  pathXCareProjectMemo: '/medispurt/projects/:id/project-memo',

  pathXCareProjectMemoDetail: '/medispurt/projects/:id/project-memo/:idnotebook',
  // xCare Message Box
  pathXCareMessageBox: '/medispurt/projects/:id/message-box',
  // xCare Message Box
  pathXCareBookMark: '/medispurt/projects/:id/BookMark',
  // xCare Project file
  pathXCareProjectFile: '/medispurt/projects/:id/project-file',
  // xCare Project page
  pathXCareProjectSetting: '/medispurt/projects/:id/project-setting',
  // xCare Project Task Template
  pathXCareProjectMember: '/medispurt/projects/:id/list-member',
};
