import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import projectApi from 'app/axios/api/projectApi';
import userInfoApi from 'app/axios/api/userInfoApi';
import { getSkillList } from 'app/pages/ResumePage/slice/resumePageSlice';
import { Roles, UserInfo, UserRole } from 'types/userInfo';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { ExpertDetailState } from './expertDetailTypes';

const initialState: ExpertDetailState = {
  user: {},
  skillList: [],
  xCareList: [],
  projectList: [],
  loading: false,
};

export const getXcareList = async () => {
  const data = await userInfoApi.getUserInfo({ role: Roles.Xcare as UserRole });
  return data.data;
};

export const getProjectList = async () => {
  const data = await projectApi.getAll();
  return data.data;
};

export const loadData = createAsyncThunk('expertDetail/loadData', async (_, thunkAPI) => {
  return await Promise.all([
    // getSkillList(),
    // getXcareList(),
    // getProjectList(),
  ]);
});

export const loadxCareListData = createAsyncThunk(
  'expertDetail/loadxCareListData',
  async (_, thunkAPI) => {
    return await Promise.any([getXcareList()]);
  },
);

const slice = createSlice({
  name: 'expertDetailPage',
  initialState,
  reducers: {
    setUserInfo(state, action: PayloadAction<UserInfo>) {
      state.user = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadData.pending, (state, action: PayloadAction<any>) => {
      state.loading = true;
    });
    builder.addCase(loadData.rejected, (state, action: PayloadAction<any>) => {
      state.loading = false;
    });
    builder.addCase(loadData.fulfilled, (state, action: PayloadAction<any>) => {
      state.skillList = action.payload[0].data;
      state.xCareList = action.payload[1].data;
      state.projectList = action.payload[2].data;
      state.loading = false;
    });
    builder.addCase(loadxCareListData.pending, (state, action: PayloadAction<any>) => {
      state.loading = true;
    });
    builder.addCase(loadxCareListData.rejected, (state, action: PayloadAction<any>) => {
      state.loading = false;
    });
    builder.addCase(loadxCareListData.fulfilled, (state, action: PayloadAction<any>) => {
      state.xCareList = action?.payload?.data;
      state.loading = false;
    });
  },
});

export const { actions, reducer: expertDetailReducer } = slice;
export const { setUserInfo } = actions;
export default expertDetailReducer;
