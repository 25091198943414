import { Checkbox as MuiCheckbox } from '@mui/material';
import c from 'clsx';
import React from 'react';
import './Checkbox.scss';

interface Props {
  className?: string;
  disabled?: boolean;
  name?: string;
  onChange?: (event) => void;
  value?: any;
  color?: 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
  checked?: boolean;
  // All other props
  [x: string]: any;
}

const Checkbox = (props: Props, ref) => {
  const {
    className = '',
    disabled = false,
    name,
    onChange,
    value,
    color = 'default',
    checked = false,
    ...rest
  } = props;
  const rootClassName = 'checkbox';

  return (
    <MuiCheckbox
      className={c(rootClassName, className)}
      disabled={disabled}
      name={name}
      value={value}
      checked={checked}
      color={color}
      onChange={onChange}
      ref={ref}
      {...rest}
    />
  );
};

export default React.forwardRef(Checkbox);
