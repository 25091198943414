import { TGetQuote, TQuote } from 'types/quote';
import { axiosClient } from '../axiosClient';
import queryString from 'query-string';

const quoteApi = {
  add: (data: TQuote) => {
    const url = '/api/quote';
    return axiosClient.post<TQuote, any>(url, data);
  },
  getList: (params: TGetQuote) => {
    const url = '/api/quote';
    const query = queryString.stringify(params);
    return axiosClient.get(`${url}?${query}`);
  },
  update: (id: string, data: TQuote) => {
    const url = `/api/quote/${id}`;
    return axiosClient.patch<TQuote, any>(url, data);
  },
  delete: (id: string) => {
    const url = `/api/quote/${id}`;
    return axiosClient.delete(url);
  },
  getId: (id: string) => {
    const url = `/api/quote/${id}`;
    return axiosClient.get(url);
  },
};

export default quoteApi;
