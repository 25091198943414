import IconCheck from 'app/components/Icons/IconCheck/IconCheck';
import React from 'react';
import { NavLink } from 'react-router-dom';
import styles from './TickLink.module.scss';

interface Props {
  link: string;
  label: string;
  isActive?: (match: boolean, location: { pathname: string }) => boolean;
}
export default function TickLink({ label, link, isActive }: Props) {
  return (
    <NavLink
      to={link}
      className={
        'text-on-surface flex-1 flex justify-center items-center link border-surface border-outline border-l first:border-l-0 ' +
        styles.link
      }
      isActive={isActive as any}
    >
      <IconCheck className={styles.check} />
      <p>{label}</p>
    </NavLink>
  );
}
