import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { NotificatitionState } from './notificationTypes';

export const DEFAULT_NOTI_DURATION = 5000;

const initialState: NotificatitionState = {
  active: false,
  duration: DEFAULT_NOTI_DURATION,
  message: '',
  type: 'success',
};

const slice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    activeNoti(state, action: PayloadAction<NotificatitionState>) {
      state.active = true;
      state.duration = action.payload.duration;
      state.message = action.payload.message;
      state.type = action.payload.type;
    },
    closeNoti(state) {
      state.active = false;
      state.duration = initialState.duration;
      state.message = initialState.message;
    },
  },
  extraReducers: (builder) => {},
});

export const { actions, reducer: notificationReducer } = slice;
export const { activeNoti, closeNoti } = actions;
export default notificationReducer;
