import queryString from 'query-string';
import { IndexedObject } from 'types';
import { isEmptyObject } from './common';

export const omitObject = (obj: IndexedObject, keys: Array<any>) => {
  if (!keys.length) return obj;
  const { [keys.pop()]: omitted, ...rest } = obj;
  return omitObject(rest, keys);
};

export const replacePathParams = (path: string, params: IndexedObject<string>): string =>
  path.replace(/:([^/]+)/g, (_, p1) => encodeURIComponent(params[p1] ? params[p1] : ''));

export const parseFloatNum = (str: string) => {
  const trimmed = str && typeof str.trim === 'function' ? str.trim() : null;
  if (!trimmed) {
    return null;
  }
  const num = parseFloat(trimmed);
  const isNumber = !isNaN(num);
  const isFullyParsedNum = isNumber && num.toString() === trimmed;
  return isFullyParsedNum ? num : null;
};
export const parse = (search: string) => {
  const params = queryString.parse(search);
  return Object.keys(params).reduce((result, key) => {
    const val = params[key];
    if (val === 'true') {
      result[key] = true;
    } else if (val === 'false') {
      result[key] = false;
    } else {
      const num = parseFloatNum(val ? val.toString() : '');
      result[key] = num === null ? val : num;
    }
    return result;
  }, {});
};

export const createQueryUrl = (location: IndexedObject, params: IndexedObject) => {
  const { pathname } = location;
  if (isEmptyObject(params)) return pathname;
  let newParams: IndexedObject = {};
  Object.keys(params).forEach((key) => {
    if (params[key]) {
      newParams[key] = params[key];
    }
  });
  const query = queryString.stringify(newParams);
  return `${pathname}?${query}`;
};

export const paramsToObject = (searchParams: string) => {
  const paramsObj = Object.fromEntries(new URLSearchParams(searchParams));
  return paramsObj;
};

export const percentCal = (cur: number | string, total: number | string, fixed: number = 0) => {
  if (!cur || !total) return 0;
  const per = (parseFloat(cur.toString()) / parseFloat(total.toString())) * 100;
  return per.toFixed(fixed);
};

export const openUrlInNewTab = (url?: string) => {
  url && window.open(url, '_blank')?.focus();
};

export const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export function formatMoney(money: number) {
  const roundedMoney = Math.round(money * 1000) / 1000;
  return roundedMoney.toLocaleString('en-US');
}
