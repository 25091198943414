import { TCreateProject } from 'app/pages/xCare/ContractSection/ProjectListPage/slice/projectListPageTypes';
import queryString from 'query-string';
import { TMasterAgreement } from 'types/masterAgreement';
import { TGetProjectList, TProjectTaskQueryParams, TTaskFileQueryParams } from 'types/project';
import { ProjectTask } from 'types/projectTask';
import { axiosClient } from '../axiosClient';

const projectApi = {
  getAll: () => {
    const url = '/api/project';
    return axiosClient.get(url);
  },
  getProjectList: (params: TGetProjectList) => {
    const url = '/api/project';
    const query = queryString.stringify(params);
    return axiosClient.get(`${url}?${query}`);
  },
  getProjectDetail: (id: string) => {
    const url = `/api/project/${id}`;
    return axiosClient.get(`${url}`);
  },
  deleteProject: (id: string) => {
    const url = `/api/project/${id}`;
    return axiosClient.delete(url);
  },
  updateProject: (data: FormData) => {
    const id = data.get('id');
    const url = `/api/project/${id}`;
    return axiosClient.patch<FormData>(url, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  createProject: (data: TCreateProject) => {
    const url = '/api/project';
    return axiosClient.post(url, data);
  },
  getProjectAgreement: (id: string) => {
    const url = `/api/projectAgreement/${id}`;
    return axiosClient.get(url);
  },
  createProjectAgreement: (data: FormData) => {
    const url = '/api/projectAgreement';
    return axiosClient.post<FormData, TMasterAgreement>(url, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  updateProjectAgreement: (data: FormData) => {
    const id = data.get('id');
    const url = `/api/projectAgreement/${id}`;
    return axiosClient.patch<FormData>(url, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  //Project task
  getProjectTask: (params: TProjectTaskQueryParams) => {
    const url = '/api/projectTask';
    const query = queryString.stringify(params);
    return axiosClient.get(`${url}?${query}`);
  },
  updateProjectTask: (id: string, data: Partial<ProjectTask>) => {
    const url = `/api/projectTask/${id}`;
    return axiosClient.patch(url, data);
  },

  //Project task file
  getProjectTaskFile: (params: TTaskFileQueryParams) => {
    const url = '/api/projectTaskFile';
    const query = queryString.stringify(params);
    return axiosClient.get(`${url}?${query}`);
  },

  getExpertCostApplication: () => {
    const url = '/project/experts-ca';
    return axiosClient.get(`${url}`);
  },

  getCostApplicationDetailsByID: (id: string) => {
    const url = `/project/ca-details/${id}`;
    return axiosClient.get(url);
  },
  createCostApplicationDetails: (id: string, data: any) => {
    const url = `/project/ca-details/${id}`;
    return axiosClient.post(url, data);
  },
};

export default projectApi;
