import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import clientApi from 'app/axios/api/clientApi';
import { RootState } from 'types';
import { TClient, TGetClientList, TMasterAgreementClient } from 'types/client';
import { TMasterAgreement } from 'types/masterAgreement';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { ClientListPageState, TUpdateClient } from './clientListPageTypes';

const initialState: ClientListPageState = {
  clientList: [],
  loading: false,
  params: {},
};

export const getClientList = async (params: TGetClientList) => {
  const res = await clientApi.getClientList(params);
  return res.data as TClient;
};

export const loadDataClientPage = createAsyncThunk(
  'clientListPage/loadDataClientPage',
  async (params: TGetClientList, thunkAPI) => {
    thunkAPI.dispatch(setParamsGetListClient(params));
    try {
      return await Promise.all([getClientList(params)]);
    } catch (err) {
      console.log(err);
    }
  },
);

export const createClient = createAsyncThunk(
  'clientListPage/createClient',
  async (data: FormData, thunkAPI) => {
    thunkAPI.dispatch(setLoadingClient(true));
    await clientApi
      .addClient(data)
      .then(() => {
        const { params } = (thunkAPI.getState() as RootState).clientListPage;
        return getClientList(params);
      })
      .catch(() => {});
    thunkAPI.dispatch(setLoadingClient(false));
  },
);

export const deleteClient = createAsyncThunk(
  'clientListPage/deleteClient',
  async (id: string, thunkAPI) => {
    thunkAPI.dispatch(setLoadingClient(true));
    await clientApi
      .deleteClient(id)
      .then(() => {
        thunkAPI.dispatch(removeClientFormList(id));
      })
      .catch(() => {});
    thunkAPI.dispatch(setLoadingClient(false));
  },
);

export const updateClient = createAsyncThunk(
  'clientListPage/updateClient',
  async (data: TUpdateClient, thunkAPI) => {
    thunkAPI.dispatch(setLoadingClient(true));
    const formData = new FormData();
    Object.keys(data).forEach((key) => formData.append(key, data[key]));
    await clientApi
      .updateClient(formData)
      .then((res) => {
        thunkAPI.dispatch(updateClientFromList((res.data as { data: TClient }).data));
      })
      .catch(() => {});
    thunkAPI.dispatch(setLoadingClient(false));
  },
);

export const updateAgreementClient = createAsyncThunk(
  'clientListPage/updateAgreementClient',
  async (data: TMasterAgreementClient, thunkAPI) => {
    thunkAPI.dispatch(setLoadingClient(true));
    const formData = new FormData();
    Object.keys(data).forEach((key) => formData.append(key, data[key]));
    await clientApi
      .updateAgreementClient(formData)
      .then((res) => {
        // thunkAPI.dispatch(updateAgreementOfClient((res.data as { data: TMasterAgreement }).data));
      })
      .catch(() => {});
    thunkAPI.dispatch(setLoadingClient(false));
  },
);

const slice = createSlice({
  name: 'clientListPage',
  initialState,
  reducers: {
    setParamsGetListClient(state, action: PayloadAction<TGetClientList>) {
      state.params = action.payload;
    },
    removeClientFormList(state, action: PayloadAction<string>) {
      state.clientList = state.clientList.filter((c) => c.id !== action.payload);
    },
    setLoadingClient(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    updateClientFromList(state, action: PayloadAction<TClient>) {
      state.clientList = state.clientList.map((c) =>
        c.id === action.payload.id ? action.payload : c,
      );
    },
    // updateAgreementOfClient(state, action: PayloadAction<TMasterAgreement>) {
    //   state.clientList = state.clientList.map((c) =>
    //     c.id === action.payload.id
    //       ? {
    //           ...c,
    //           attributes: {
    //             ...c.attributes,
    //             masteragreement: {
    //               ...c.attributes?.masteragreement,
    //               status: action.payload.attributes?.status,
    //             },
    //           },
    //         }
    //       : c,
    //   );
    // },
  },
  extraReducers: (builder) => {
    builder.addCase(loadDataClientPage.pending, (state: ClientListPageState) => {
      state.loading = true;
    });
    builder.addCase(loadDataClientPage.rejected, (state: ClientListPageState) => {
      state.loading = false;
      state.clientList = [];
    });
    builder.addCase(
      loadDataClientPage.fulfilled,
      (state: ClientListPageState, action: PayloadAction<any>) => {
        state.clientList = action.payload[0].data;
        state.loading = false;
      },
    );
  },
});

export const { actions, reducer: clientListPageReducer } = slice;
export const {
  removeClientFormList,
  setParamsGetListClient,
  setLoadingClient,
  updateClientFromList,
  // updateAgreementOfClient,
} = actions;
export default clientListPageReducer;
