import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import clientApi from 'app/axios/api/clientApi';
import { TClient } from 'types/client';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { ClientDetailState } from './clientPageTypes';

const initialState: ClientDetailState = {
  clientDetail: {},
  addressList: [],
  loading: false,
};

const getClientDetail = async (id: string) => {
  const res = await clientApi.getClientDetail(id);
  return res.data as TClient;
};

export const loadData = createAsyncThunk(
  'clientDetailPage/loadData',
  async (id: string, thunkApi) => {
    try {
      return await Promise.all([getClientDetail(id)]);
    } catch (err) {
      console.log(err);
    }
  },
);

const slice = createSlice({
  name: 'clientDetailPage',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadData.pending, (state: ClientDetailState) => {
      state.loading = true;
    });
    builder.addCase(loadData.rejected, (state: ClientDetailState) => {
      state.loading = false;
      state.clientDetail = {};
    });
    builder.addCase(loadData.fulfilled, (state: ClientDetailState, action: PayloadAction<any>) => {
      state.clientDetail = action.payload[0].data;
      state.loading = false;
    });
  },
});

export const { actions, reducer: clienDetailPageReducer } = slice;
export default clienDetailPageReducer;
