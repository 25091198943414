import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { DocumentListState } from './ProjectMemoTypes';

const initialState: DocumentListState = {
  documentList: [],
  loading: false,
};

export const getDocumentList = createAsyncThunk('document/list', async () => {
  const data = await fetch(
    'https://notebook-beta-backend.inteam.jp/document/list?creator_id=27&doc_status=1&doc_status=2&order_by=-updated_at&page=1&page_size=100',
  ).then((data) => data.json());

  return data.data;
});

const slice = createSlice({
  name: 'listMemberPage',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getDocumentList.pending, (state, action: PayloadAction<any>) => {
      state.loading = true;
    });
    builder.addCase(getDocumentList.rejected, (state, action: PayloadAction<any>) => {
      state.loading = false;
    });
    builder.addCase(getDocumentList.fulfilled, (state, action: PayloadAction<any>) => {
      state.documentList = action.payload;
      state.loading = false;
    });
  },
});

export const { actions, reducer: documentListReducer } = slice;
export default documentListReducer;
