import useSWR, { useSWRConfig } from 'swr';
import useSWRMutation, { MutationFetcher } from 'swr/mutation';
import { IsArrData, ValueData } from 'types/types';
import { axiosClient } from 'app/axios/axiosClient';
import { getApiData } from './promise';
import { UrlObj } from './object/url';
import { useDispatch } from 'react-redux';
import { capitalize } from 'lodash';
import {
  DEFAULT_NOTI_DURATION,
  activeNoti,
} from 'app/components/Notification/slice/notificationSlice';
import { convertToTitleCase, generateErrorMessage } from './func';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const modelToName = {
  businessDivision1: 'jobTag 1',
  businessDivision2: 'jobTag 2',
};

// export const NO_FILE = Symbol('No File');

const methodToName = {
  POST: 'create',
  PATCH: 'update',
  DELETE: 'delete',
} as const;

function useGetAll<S extends string>(url: S) {
  return useSWR<{
    data: {
      data: ValueData<S>;
      links?: {
        total: number;
        total_pages: number;
        page_size: number;
        current_page: number;
      };
    };
  }>(url, (arg) => axiosClient.get(`/api/${arg}`), {
    keepPreviousData: true,
  });
}

export function useGet<S extends string>(url: S) {
  const { data, ...result } = useGetAll(url);
  const modified = useMemo(() => (data ? getApiData(data) : data), [data]);
  return {
    data: modified,
    ...result,
  };
}
export function useGetMetaData<S extends string>(url: S) {
  const { data, ...result } = useGetAll(url);
  const modified = useMemo(() => (data ? { links: data.data.links } : data), [data]);
  return {
    data: modified,
    ...result,
  };
}

type AvailableMutation<S extends string> = IsArrData<S> extends true ? 'POST' : 'PATCH' | 'DELETE';

interface MutationOption {
  otherInvalidate?: string[];
  sucessMessage?: string;
  errorMessage?: string;
  noMessage?: boolean;
  formData?: boolean;
}

export function useMutation<S extends string>(
  url: S,
  method: AvailableMutation<S>,
  option?: MutationOption, // pass this when you mutate one model and want it invalidate cache of other model
) {
  const otherInvalidate = option?.otherInvalidate || [];
  const dispatch = useDispatch();
  const model = url.split('/')[0].split('?')[0];
  const { i18n } = useTranslation();
  const name = convertToTitleCase(model in modelToName ? modelToName[model] : model);
  const handleChange = (e: Promise<any>) =>
    e
      .then((e) => {
        !option?.noMessage &&
          dispatch(
            activeNoti({
              duration: DEFAULT_NOTI_DURATION,
              message:
                i18n.language === 'ja'
                  ? generateErrorMessage(model, method, true)
                  : `${capitalize(methodToName[method])} ${name} success!`,
              type: 'success',
            }),
          );
        return e;
      })
      .catch((e) => {
        dispatch(
          activeNoti({
            duration: DEFAULT_NOTI_DURATION,
            message:
              i18n.language === 'ja'
                ? generateErrorMessage(model, method, false)
                : `${capitalize(methodToName[method])} ${name} unsucess!`,
            type: 'error',
          }),
        );
      });

  const { mutate } = useSWRConfig();
  const sendRequest: MutationFetcher = async (key, { arg }) => {
    let result;
    const config = Object.values(arg).some((i) => {
      return (
        option?.formData || (!Array.isArray(i) && (i instanceof File || i instanceof FileList))
      );
    })
      ? {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      : {};
    if (method === 'PATCH') {
      result = await handleChange(axiosClient.patch(`/api/${key}`, arg, config));
      console.log('handleChange result', result);
    } else if (method === 'DELETE') {
      result = await handleChange(axiosClient.delete(`/api/${key}`, arg));
    } else if (method === 'POST') {
      result = await handleChange(axiosClient.post(`/api/${key}`, arg, config));
    }
    await mutate(
      (mutateKey: string | undefined) =>
        mutateKey &&
        [url, ...otherInvalidate].some((invalUrl) => {
          const willMutate = new UrlObj(invalUrl).willInvalidate(new UrlObj(mutateKey));
          // console.log(`The url is ${invalUrl} validating ${mutateKey} with result ${willMutate}`);
          return willMutate;
        }),
    );
    return getApiData(result) as ValueData<S>;
  };
  return useSWRMutation<any, ValueData<S>, any, any>(url, sendRequest);
}
