import React from 'react';
import '../Icons.scss';

interface Props {
  className?: string;
  rotate?: boolean;
}
export default function IconArrow({ rotate = false, className }: Props) {
  return (
    <div className={className}>
      <svg
        width="8"
        height="4"
        viewBox="0 0 8 4"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          rotate: rotate ? '360deg' : '270deg',
          transformOrigin: 'center',
          transition: '0.3s ease-in',
        }}
      >
        <path
          d="M0.666016 0.333496L3.99935 3.66683L7.33268 0.333496H0.666016Z"
          fill="currentColor"
        />
      </svg>
    </div>
  );
}
