import IconArrow from 'app/components/Icons/IconArrow/IconArrow';
import BaseOption from 'app/components/common/BaseSelect/BaseOption';
import useOutsideClick from 'app/helpers/customHook/useOutsideClick';
import { translations } from 'locales/translations';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Choose, MaybeReadonly, Option } from 'types/types';

type Helper<T extends Choose> = T extends 'multiple' ? string[] : string;
type Props<T extends Choose> = {
  className?: string;
  choose: T;
  option: MaybeReadonly<Option[]>;
  value?: Helper<T>;
  callback: (arg: Helper<T>) => void;
  placeHolder?: string;
};
export default function BaseSelect<T extends Choose>(props: Props<T>) {
  const { t } = useTranslation();
  const main = useRef(null as HTMLDivElement | null);
  const [selecting, setSelecting] = useState(false);

  const getLabel = useCallback(
    (arg: string) => props.option?.find((i) => i.value === arg)?.label,
    [props.option],
  );
  const showValue = useMemo(() => {
    if (props.value)
      return Array.isArray(props.value)
        ? props.value.map((i) => getLabel(i)).join(',')
        : getLabel(String(props.value));
    return props.placeHolder;
  }, [props.value, props.placeHolder, getLabel]);

  useOutsideClick(main, () => setSelecting(false));

  return (
    <div
      className={`cursor-default border h-10 rounded-md border-outline relative ${props.className}`}
      ref={main}
    >
      <div
        className={
          'cursor-default over-lay flex items-center overflow-hidden whitespace-nowrap px-4 !absolute'
        }
        onClick={() => setSelecting(!selecting)}
      >
        {t(translations.PROJECTS[showValue || '']) || showValue}
        <IconArrow className={`absolute top-1/2 -translate-y-1/2 right-2 rotate-90`} />
      </div>
      {selecting && (
        <div
          className={
            'base-selector cursor-default max-h-96 overflow-y-auto absolute top-full left-1/2 py-2 -translate-x-1/2 !z-[999]'
          }
        >
          <div className="py-2 rounded-md shadow-medium bg-white">
            <div className={'text-black cursor-default'}>
              {props.option.map((o) => (
                <BaseOption
                  value={props.value || ''}
                  onClick={(arg) => {
                    if (props.choose === 'single') {
                      setSelecting(!selecting);
                    }
                    props.callback(arg as any);
                  }}
                  option={o}
                  key={o.value}
                  choose={props.choose}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
