import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { AuthenticationDetails, CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';
import userInfoApi from 'app/axios/api/userInfoApi';
import { UserInfo } from 'types/userInfo';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { removeLocalItem, setLocalItem } from 'utils/localStorage';
import Pool from '../config/UserPool';
import { AuthState } from './authTypes';

export const DEFAULT_PASSWORD = '12345678';

const initialState: AuthState = {
  authenticated: false,
  currentUser: {},
  cognitoEmail: '',
  loading: false,
};

export const getCognitoUser = (cognitoEmail: string) => {
  if (cognitoEmail) {
    const userData = {
      Username: cognitoEmail ?? '',
      Pool,
    };
    return new CognitoUser(userData);
  }
  return null;
};

export const authenticateCognitoUser: (
  user: CognitoUser,
  authDetails: AuthenticationDetails,
  handleSuccess: (data: CognitoUserSession) => void,
  handleError: (err: any) => void,
  handleNewPassword: (data: any) => void,
) => void = (user, authDetails, handleSuccess, handleError, handleNewPassword) => {
  user.authenticateUser(authDetails, {
    onSuccess: (data) => {
      handleSuccess(data);
    },
    onFailure: (err) => {
      handleError(err);
    },
    newPasswordRequired: (data) => {
      handleNewPassword(data);
    },
  });
};

export const setCognitoUserData = createAsyncThunk(
  'auth/setCognitoUserData',
  async (_, thunkApi) => {
    const { dispatch } = thunkApi;
    try {
      const user = await getSession();
      user.getUserData((err, data) => {
        if (err) {
          console.log(err);
        } else {
          const cognitoEmail = data?.UserAttributes[2]?.Value ?? '';
          dispatch(setUserEmail(cognitoEmail));
        }
      });
    } catch (err) {
      throw err;
    }
  },
);

export const saveLocalToken = (token: string, refreshToken: string) => {
  setLocalItem('token', token);
  setLocalItem('refreshToken', refreshToken);
};

// Get Cognito session and return current Cognito user
export const getSession: () => Promise<CognitoUser> = async () => {
  return await new Promise<CognitoUser>((resolve, reject) => {
    const user = Pool.getCurrentUser();
    if (user) {
      user.getSession((err, session) => {
        if (err) {
          console.log(err);
          reject(err);
        } else {
          resolve(user as CognitoUser);
        }
      });
    } else {
      reject(new Error('User is not authenticated'));
    }
  });
};

// Get current Django user
export const getCurrentUser = createAsyncThunk('auth/getCurrentUser', async (_, thunkApi) => {
  const data = await userInfoApi.getCurrentUser();
  return data.data;
});

// Log out from Cognito Pool
export const logout = createAsyncThunk('auth/logout', async (_, thunkApi) => {
  const user = Pool.getCurrentUser();
  if (user) {
    return user.signOut();
  }
});

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuth(state) {
      state.authenticated = true;
    },
    removeAuth(state) {
      state.authenticated = false;
      state.currentUser = {};
    },
    setUserEmail(state, action: PayloadAction<string>) {
      setLocalItem('cognitoEmail', action.payload);
      state.cognitoEmail = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCurrentUser.pending, (state, action: PayloadAction<any>) => {
      state.loading = true;
    });
    builder.addCase(getCurrentUser.rejected, (state, action: PayloadAction<any>) => {
      state.authenticated = false;
      state.loading = false;
    });
    builder.addCase(getCurrentUser.fulfilled, (state, action: PayloadAction<any>) => {
      const userData = action.payload.data as UserInfo;
      state.authenticated = true;
      state.currentUser = userData;
      state.loading = false;
      setLocalItem('localUser', userData);
    });
    builder.addCase(logout.fulfilled, (state, action: PayloadAction<any>) => {
      state.authenticated = false;
      state.currentUser = {};
      removeLocalItem('token');
      removeLocalItem('refreshToken');
      removeLocalItem('cognitoEmail');
    });
  },
});

export const { actions, reducer: authReducer } = slice;
export const { setAuth, removeAuth, setUserEmail } = actions;
export default authReducer;
