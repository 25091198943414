// Genres
import { FilterData } from 'app/components/common/Filter/FilterType';
import i18next from 'i18next';
import { translations } from 'locales/translations';
import { ProjectStatusT, StatusOption, StatusType } from 'types/quoteType';
import { MaybeReadonly, Option } from 'types/types';

export const GENRE_MEDICINE = 'Pharmaceuticals';
export const GENRE_INTRUSMENTS = 'Medical instruments';
export const GENRE_DIAGNOSTICS = 'In-vitro diagnostics';
export const GENRE_REGENERATIVE = 'Regenerative medicine';
export const GENRE_OTHER = 'Other';
export const PRIVACY_POLICY =
  'https://evanescent-aphid-8f4.notion.site/d4924a00304642d4a6afa8c2b7f38271';

// Working time
export const HOUR_10 = '0~10 hours a month';
export const HOUR_20 = '0~20 hours a month';
export const HOUR_30 = '0~30 hours a month';
export const HOUR_40 = '0~40 hours a month';
export const HOUR_80 = '0~80 hours a month';
export const HOUR_81 = '81 or more';
// export const HOUR_41 = '~ 41-80 hours a month';

export const HOUR_10_JA = '〜月0-10時間';
export const HOUR_20_JA = '〜月0-20時間';
export const HOUR_30_JA = '〜月0-30時間';
export const HOUR_40_JA = '〜月0-40時間';
export const HOUR_80_JA = '〜月0-80時間';
export const HOUR_81_JA = '81以上';
// export const HOUR_41_JA = '〜月41-80時間';

// Working day
export const WEEKEND_AND_HOLIDAYS_ONLY = 'Weekend and holidays only';
export const WEEKDAY_NIGHTS_WEEKEND_AND_HOLIDAYS = 'Weekday nights + weekend and holidays';
export const ALL_DAYS = 'All days';
export const WEEKDAY_DAY = 'Weekday daytime';
export const WEEKDAY_NIGHTS = 'Weekday nights';
export const AVAILABLE_ONLY_HOLIDAY = 'Available only holiday';
export const WORK_WEEKDAY_DAY = 'All day';
export const WEEKDAY_OTHER = 'Others';

export const WEEKEND_AND_HOLIDAYS_ONLY_JA = '土日祝日のみ';
export const WEEKDAY_NIGHTS_WEEKEND_AND_HOLIDAYS_JA = '平日夜間 + 土日祝日';
export const ALL_DAYS_JA = '全日';
export const WEEKDAY_DAY_JA = '平日昼間';
export const WEEKDAY_NIGHTS_JA = '平日夜間';
export const AVAILABLE_ONLY_HOLIDAY_JA = '土日祝日';
export const WORK_WEEKDAY_DAY_JA = '全日';
export const WEEKDAY_OTHER_JA = 'その他';

// Contact
export const CONTACT_FACEBOOK = 'Facebook';
export const CONTACT_LINKEDIN = 'LinkedIn';
export const CONTACT_OTHER = 'Other';

export const statusOption = [
  { value: 'IN_PROGRESS', label: 'IN PROGRESS' },
  { value: 'DONE', label: 'DONE' },
  { value: 'READY', label: 'READY' },
];

export const priorityOption = [
  { value: 'UR', label: 'Urgent' },
  { value: 'NU', label: 'None-Urgent' },
] as const;

export const personOption = [
  { value: '29', label: 'Nguyen Nam' },
  { value: '16', label: 'OSAMU ASAHINA' },
  { value: '6', label: 'nam nguyen' },
];

export const FilterOptionProps = (option: MaybeReadonly<Option[]>): FilterData[] => [
  {
    key: { value: 'person_in_charge', label: 'ASSIGNEE' },
    type: 'multiple',
    listOption: option,
  },
  {
    key: { value: 'creator', label: 'CREATOR' },
    type: 'multiple',
    listOption: option,
  },
  {
    key: { value: 'priority', label: 'PRIORITY' },
    type: 'multiple',
    listOption: [
      { value: 'UR', label: 'Urgent' },
      { value: 'NU', label: 'Not Urgent' },
    ],
  },
  {
    key: { value: 'start_date', label: 'START_DATE' },
    type: 'multiple',
    listOption: [
      { value: 'today', label: 'Today' },
      { value: 'tomorrow', label: 'Today Tomorrow' },
      { value: 'next_week', label: 'Next week' },
      { value: 'next_2_week', label: 'Next 2 week' },
      { value: 'next_month', label: 'Next month' },
      { value: 'next_3_month', label: 'Next 3 months' },
      { value: 'after_today', label: 'After today' },
      { value: 'last_week', label: 'Last week' },
      { value: 'last_month', label: 'Last month' },
      { value: 'last_3_months', label: 'Last 3 months' },
    ],
  },
  {
    key: { value: 'end_date', label: 'END_DATE' },
    type: 'multiple',
    listOption: [
      { value: 'today', label: 'TODAY' },
      { value: 'tomorrow', label: 'TOMORROW' },
      { value: 'next_week', label: 'NEXT_WEEK' },
      { value: 'next_2_week', label: 'NEXT_2_WEEK' },
      { value: 'next_month', label: 'NEXT_MONTH' },
      { value: 'next_3_month', label: 'NEXT_3_MONTH' },
      { value: 'after_today', label: 'AFTER_TODAY' },
      { value: 'last_week', label: 'LAST_WEEK' },
      { value: 'last_month', label: 'LAST_MONTH' },
      { value: 'last_3_months', label: 'LAST_3_MONTH' },
    ],
  },
  {
    key: { value: 'status', label: 'STATUS' },
    type: 'multiple',
    listOption: StatusOption,
  },
];

export const FilterOptionExpert = (
  occupationList: Option[],
  projectList: Option[],
  careerList: Option[],
  detailedItemList: Option[],
  modalityList: Option[],
  countryList: Option[],
  t,
): FilterData[] => [
  // {
  //   key: { value: 'genre', label: 'DEVELOPMENT_AREA' },
  //   type: 'multiple',
  //   listOption: [
  //     {
  //       value: 'genre_medicine',
  //       label: 'PHARMACEUTICALS',
  //       label2: t(translations.PROJECTS.PHARMACEUTICALS),
  //     },
  //     {
  //       value: 'genre_instruments',
  //       label: 'MEDICAL_INSTRUMENTS',
  //       label2: t(translations.PROJECTS.MEDICAL_INSTRUMENTS),
  //     },
  //     {
  //       value: 'genre_diagnostics',
  //       label: 'GENRE_DIAGNOSTICS',
  //       label2: t(translations.PROJECTS.GENRE_DIAGNOSTICS),
  //     },
  //     {
  //       value: 'genre_regenerative',
  //       label: 'REGENERATIVE_MEDICINE',
  //       label2: t(translations.PROJECTS.REGENERATIVE_MEDICINE),
  //     },
  //     {
  //       value: 'genre_other',
  //       label: 'OTHERS',
  //       label2: t(translations.PROJECTS.OTHERS),
  //     },
  //   ],
  // },
  {
    key: { value: 'category', label: t(translations.CLIENTS.CATEGORY) },
    type: 'multiple',
    listOption: careerList,
  },
  {
    key: { value: 'occupation', label: 'SKILL' },
    type: 'multiple',
    listOption: occupationList,
  },

  {
    key: { value: 'detail_item', label: t(translations.RESUME.DETAILED_ITEM) },
    type: 'multiple',
    listOption: detailedItemList,
  },
  {
    key: { value: 'modality_area', label: t(translations.RESUME.MODALITY_AREA) },
    type: 'multiple',
    listOption: modalityList,
  },
  {
    key: { value: 'project', label: 'PROJECT_LIST' },
    type: 'multiple',
    listOption: projectList,
  },
  {
    key: { value: 'residence', label: t(translations.EXPERT.NATIONALITY) },
    type: 'multiple',
    listOption: countryList,
  },
  {
    key: { value: 'score', label: 'SCORE' },
    type: 'multiple',
    listOption: [
      { value: '0', label: '0~0.5未満' },
      { value: '1', label: '0.5~1.5未満' },
      { value: '2', label: '1.5~2.5未満' },
      { value: '3', label: '2.5~3.5未満' },
      { value: '4', label: '3.5~4.5未満' },
      { value: '5', label: '4.5~5.0' },
    ],
  },
  // {
  //   key: { value: 'level_B', label: t(translations.PROJECTS.SKILL) },
  //   type: 'multiple',
  //   listOption: occupationList,
  // },
];

// Regex
export const phoneRegex = /^[0-9]{0,24}$/;
export const codeRegex = /^\d{6}$/;
export const fblinkRegex = /^(\d+|(https?:\/\/)?(www\.)?facebook\.com\/.+)?$/;
export const linkUrlRegex = /^((https?:\/\/)?([\w-]+\.)+[\w-]+(\/[\w-./?%&=]*)?)?$/;
export const otherSocialRegex = /^$|^[A-Za-z0-9\s]+$/;
export const passwordRegex = /^[a-zA-Z0-9!"#$%&'()-^@[\];:,./=~|]{8,}$/;

//required all
export const passWordResetRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]*$/g;

//colors notebook
export const colors = [
  '#F47F66',
  '#99A85A',
  '#5AA886',
  '#4BADAA',
  '#C9D5A5',
  '#656B9C',
  '#9068A7',
  '#C682B6',
  '#A24C6F',
  '#D09C8F',
  '#7AB5C8',
];

export const BodyEmailNotiNewMessageJapan = (
  projectName: string,
  taskName: string,
  avatar: string,
  message: string,
  url: string,
  nameUser: string,
  time: string,
) => {
  return `
  <html>
  <head>
    <style>
      body {
        font-family: 'Noto Sans', sans-serif;
      }
      .container {
        max-width: 850px;
        margin: 0 auto;
        padding: 20px;
        border: 1px solid #ddd;
        border-radius: 5px;
        background-color: #f9f9f9;
        color: black;
        font-size: 16px;
      }
      h1 {
        margin-bottom: 8px !important;
      }
      h4 {
        margin: 0px 16px 16px 0px !important;
      }
      .url_task {
        color: gray;
        text-decoration: underline;
      }
      .content_message {
        display: flex;
        margin-bottom: 48px;
      }
      .avatar_image {
        margin-right: 16px;
      }
      p {
        margin: 0px !important;
      }
      .user {
        display: flex;
      }
      .user_name {
        text-decoration: underline;
      }
    </style>
  </head>
  <body>
    <div class="container"> 
      <h1>1件の新しいメンションが届いています。</h1>
      <h2>${projectName}</h2>
      <a href=${url} target="_blank">${taskName}に関して</a>
      <div class="content_message">
        <img src="${avatar}" alt="avatar" width="50px" height="50px" class="avatar_image"/>
        <div>
          <div class="user">
            <h4 class="user_name">${nameUser}</h4>
            <p>${time}</p>
          </div>
          <div>${message}</div>
        </div>
      </div>
      <div class="disclaimer">
        ※このメールには返信いただけません。タスク名をクリックし、xCAREシステムのメッセージから返信してください。
      </div>
      <div class="disclaimer">
        ※本メールにお心当たりがない場合、お手数ですが、本メールは破棄してください。
      </div>
      <hr />
      <p>株式会社xCARE（クロスケア）</p>
      <p>〒103-0027 東京都中央区日本橋1丁目2番10号東洋ビル6階</p>
      <p><a href="https://www.xcare-medical.com/">https://www.xcare-medical.com/</a></p>
    </div>
  </body>
  </html>
  `;
};

export const STATUS_TASK: StatusType[] = [
  'NOT_STARTED_YET',
  'IN_PROGRESS',
  'DONE',
  'REVIEWED',
  'FINISHED',
  'TRY_AGAIN',
  'PENDING',
  'STOP',
];

export const STATUS_PROJECT = {
  FINISHED: 1,
  IN_PROGRESS_2: 2,
  NOT_STARTED_YET: 3,
  LOST_ORDER: 4,
  OTHERS: 5,
};

export const MAX_TOTAL_TASK = 1000;
