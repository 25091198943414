import IconFileAccount from 'app/components/Icons/IconFileAccount/IconFileAccount';
import IconLogout from 'app/components/Icons/IconLogout/IconLogout';
import IconUser from 'app/components/Icons/IconUser/IconUser';
import IconUserAccount from 'app/components/Icons/IconUserAccount/IconUserAccount';
import Logo from 'app/components/Icons/Logo/Logo';
import SelectLanguage from 'app/components/common/Select/SelectLanguage';
import TickLinkGroup from 'app/components/common/TickLink/TickLinkGroup';
import useOutsideClick from 'app/helpers/customHook/useOutsideClick';
import { replacePathParams } from 'app/helpers/functions';
import { logout } from 'app/pages/Auth/slice/authSlice';
import { Epath } from 'app/routes/routesConfig';
import { translations } from 'locales/translations';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import { RootState } from 'types';
import useLocaleStorage from 'utils/use/useLocalStorage';
import { useGet } from 'utils/useGetData';
import './Header.scss';
import FileDownloader from 'app/components/ImageLoader';
import { Auth } from 'aws-amplify';

const Header = () => {
  const { t } = useTranslation();
  const { currentUser } = useSelector((state: RootState) => state.auth);
  const { data: listProject } = useGet('project');

  const dispatch = useDispatch();
  const history = useHistory();
  const userMenuRef = React.useRef<HTMLDivElement>(null);

  const [value] = useLocaleStorage('projectId');
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const numProject = useMemo(() => listProject?.length, [listProject]);

  const firstProject = useMemo(
    () => (numProject && listProject ? listProject[0].id : ''),
    [listProject, numProject],
  );

  const handleLogout = () => {
    sessionStorage.clear();
    localStorage.clear();
    dispatch(logout());
    Auth.signOut();
  };
  const [selectedOption, setSelectedOption] = useState('JA');

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleNextPage = (path: string) => {
    history.push(path);
  };

  const toggleDropdown = () => {
    setIsUserMenuOpen((prevState) => !prevState);
  };

  useOutsideClick(userMenuRef, () => setIsUserMenuOpen(false));

  const isShowNavHeader = useMemo(() => {
    return currentUser.attributes?.role === 'XC';
  }, [currentUser.attributes?.role]);

  const isExpert = useMemo(() => {
    return currentUser.attributes?.role === 'EX';
  }, [currentUser.attributes?.role]);

  // tick link group
  const noProject = useMemo(() => listProject?.length === 0, [listProject]);

  const listLink = useMemo(
    () =>
      noProject
        ? [
            {
              link: Epath.pathXCareExpertList,
              label: t(translations.HEADER.EXPERT_MANAGEMENT),
            },
            {
              link: Epath.pathXCareClientLayout,
              label: t(translations.HEADER.CONTACT_MANAGEMENT),
            },
            {
              link: Epath.pathResumePage,
              label: t(translations.HEADER.RESUME),
            },
            {
              link: Epath.pathOpManagementPage,
              label: t(translations.HEADER.OPERATIONAL_MANAGEMENT),
            },
          ]
        : [
            {
              link: Epath.pathXCareExpertList,
              label: t(translations.HEADER.EXPERT_MANAGEMENT),
            },
            {
              link: Epath.pathXCareClientLayout,
              label: t(translations.HEADER.CONTACT_MANAGEMENT),
            },
            {
              link: replacePathParams(Epath.pathXCareProjectManagement, {
                id: value || firstProject,
              }),
              label: t(translations.HEADER.PROJECT_MANAGEMENT),
              isActive: (_, location) =>
                location.pathname.indexOf(Epath.pathXCareProjectManagement) !== -1,
            },
            {
              link: Epath.pathResumePage,
              label: t(translations.HEADER.RESUME),
            },
            {
              link: Epath.pathOpManagementPage,
              label: t(translations.HEADER.OPERATIONAL_MANAGEMENT),
            },
          ],

    [firstProject, noProject, t, value],
  );

  const expertHomeTabLink = useMemo(
    () => [
      {
        link: Epath.pathResumePage,
        label: t(translations.HEADER.RESUME),
      },
      {
        link: Epath.pathOpManagementPage,
        label: t(translations.HEADER.OPERATIONAL_MANAGEMENT),
      },
    ],
    [t],
  );

  return (
    <div className="nav-header relative">
      <div className="nav-left">
        <div>
          <NavLink to={Epath.pathResumePage}>
            <Logo size={116} />
          </NavLink>
        </div>
      </div>
      <div className="absolute-center">
        {isShowNavHeader && (
          <TickLinkGroup
            // className="w-[903px] h-9"
            className="w-[1150px] h-9"
            links={listLink}
          />
        )}
        {isExpert && <TickLinkGroup className="w-[603px] h-9" links={expertHomeTabLink} />}
      </div>
      <div className="nav-right">
        <SelectLanguage
          value={selectedOption}
          onChange={handleChange}
          className="select-dropdown"
        />
        <div className="use-header" ref={userMenuRef}>
          <div className="basic-menu">
            <div className="avatar" onClick={toggleDropdown}>
              {currentUser?.attributes?.avatar_image ? (
                <FileDownloader imagePath={currentUser.attributes.avatar_image} alt="avatar" />
              ) : (
                <IconUser />
              )}
            </div>
            {isUserMenuOpen && (
              <div className="dropdown">
                <div
                  className="use-dropdown"
                  onClick={() => {
                    handleNextPage(Epath.pathChangePassword);
                    setIsUserMenuOpen(false);
                  }}
                >
                  <p className="p-dropdown">
                    <IconUserAccount size={24} />
                    {t(translations.HEADER.ACCOUNT)}
                  </p>
                </div>
                <div
                  className="use-dropdown"
                  onClick={() => {
                    handleNextPage(Epath.pathResumePage);
                    setIsUserMenuOpen(false);
                  }}
                >
                  <p className="p-dropdown">
                    <IconFileAccount size={24} />
                    {t(translations.HOME.MENUS_RESUME)}
                  </p>
                </div>
                <div className="use-dropdown" onClick={handleLogout}>
                  <p className="p-dropdown">
                    <IconLogout size={24} />
                    {t(translations.HEADER.LOGOUT)}
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
