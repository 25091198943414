import _ from 'lodash';

export const DEFAULT_DATE_FORMAT = 'YYYY/MM/DD';
export const DEFAULT_DATE_FORMAT_2 = 'YYYY-MM-DD';
export const DEFAULT_FORMAT_DATE_TIME = 'YYYY/MM/DD HH:mm';

export const monthList = new Array(12).fill(null).map((month, index) => index + 1);

export const convertToJpDate = (date: string) => {
  if (date) {
    var d = new Date(date);
    var lang = 'en',
      year = d.toLocaleString(lang, { year: 'numeric' }),
      month = d.toLocaleString(lang, { month: 'numeric' }),
      day = d.toLocaleString(lang, { day: 'numeric' });

    return `${year}年 ${month}月 ${day}日`;
  }
  return '';
};

export const getAge = ({ year, month, day }: any) => {
  if (year && month && day) {
    const birthDate: any = new Date(`${year}-${month}-${day}`);
    const today: any = new Date();
    const ageDiffMs = today - birthDate;
    const ageDate = new Date(ageDiffMs);
    const years = _.floor(_.divide(_.subtract(ageDate.getUTCFullYear(), 1970), 1));
    const months = _.floor(_.divide(ageDate.getUTCMonth(), 1));

    if (years || months) {
      return { years, months };
    }
  }
};
