import queryString from 'query-string';
import { axiosClient } from '../axiosClient';
import { TAddAssign, TAssignQueryParams, TUpdateAssign } from './types/assignTypes';

const assignApi = {
  get: (params: TAssignQueryParams) => {
    const url = '/api/assign';
    const query = queryString.stringify(params);
    return axiosClient.get(`${url}?${query}`);
  },
  add: (data: TAddAssign) => {
    const url = '/api/assign';
    return axiosClient.post(url, data);
  },
  update: (data: TUpdateAssign) => {
    const url = `/api/assign/${data.id}`;
    return axiosClient.put(url, data);
  },
  delete: (assignId: number) => {
    const url = `/api/assign/${assignId}`;
    return axiosClient.delete(url);
  },
};

export default assignApi;
