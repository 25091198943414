export type StatusType =
  | 'NOT_STARTED_YET'
  | 'IN_PROGRESS'
  | 'DONE'
  | 'REVIEWED'
  | 'FINISHED'
  | 'PENDING'
  | 'TRY_AGAIN'
  | 'STOP';

export type ProjectStatusT =
  | 'FINISHED'
  | 'IN_PROGRESS_2'
  | 'NOT_STARTED_YET'
  | 'LOST_ORDER'
  | 'OTHERS';

export type Priority = 'UR' | 'NU';

export const StatusOption = [
  {
    value: 'NOT_STARTED_YET',
    label: 'NOT_STARTED_YET',
  },
  {
    value: 'DONE',
    label: 'DONE',
  },
  {
    value: 'IN_PROGRESS',
    label: 'IN_PROGRESS',
  },
  {
    value: 'REVIEWED',
    label: 'REVIEWED',
  },
  {
    value: 'FINISHED',
    label: 'FINISHED',
  },
  {
    value: 'TRY_AGAIN',
    label: 'TRY_AGAIN',
  },
  {
    value: 'PENDING',
    label: 'PENDING',
  },
  {
    value: 'STOP',
    label: 'STOP',
  },
] as const;

export type QuoteTask = {
  id: number;
  name: string;
  heading: boolean;
  unit_price: number;
  quantity: number;
  unit: string;
  priority: Priority;
  create_date: string;
  start_date: string;
  end_date: string;
  status: StatusType;
  estimate_time: number;
  operating_time: number;
  share_note: string;
  quote: number;
  business_division1: number;
  business_division2: number;
  person_in_charge: number[];
  tag: number[];
};

export type QuoteType = {
  task_set: QuoteTask[];
  name: string;
  memo: string;
  value: number;
  date: string;
  project: number;
  priority: Priority;
  create_date: string;
  start_date: string;
  end_date: string;
  status: StatusType;
  person_in_charge: number[];
};
