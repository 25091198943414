import queryString from 'query-string';
import { TClient, TGetClientList } from 'types/client';
import { axiosClient } from '../axiosClient';

const clientApi = {
  getClientList: (params: TGetClientList) => {
    const url = '/api/client';
    const query = queryString.stringify(params);
    return axiosClient.get(`${url}?${query}`);
  },
  addClient: (data: FormData) => {
    const url = '/api/client';
    return axiosClient.post<FormData>(url, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  getClientDetail: (id: string) => {
    const url = `/api/client/${id}`;
    return axiosClient.get(`${url}`);
  },
  deleteClient: (id: string) => {
    const url = `/api/client/${id}`;
    return axiosClient.delete(url);
  },
  updateClient: (data: FormData) => {
    const url = `/api/client/${data.get('id')}`;
    return axiosClient.patch<FormData, { data: TClient }>(url, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  createAgreementClient: (data: FormData) => {
    const url = `/api/masterAgreement`;
    return axiosClient.post<FormData, { data: TClient }>(url, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  updateAgreementClient: (data: FormData) => {
    const url = `/api/masterAgreement/${data.get('id')}`;
    return axiosClient.patch<FormData, { data: TClient }>(url, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  deleteAgreementClient: (id: string) => {
    const url = `/api/masterAgreement/${id}`;
    return axiosClient.delete(url);
  },
};

export default clientApi;
