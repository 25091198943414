import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import {
  getExpertCareerMasterList,
  getNationalList,
  getSkillList,
} from 'app/pages/ResumePage/slice/resumePageSlice';
import { UserInfo } from 'types/userInfo';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { getProjectList, getXcareList } from '../../ExpertDetailPage/slice/expertDetailSlice';
import { ExpertDetailEditState } from './expertDetailEditTypes';

const initialState: ExpertDetailEditState = {
  user: {},
  skillList: [],
  xCareList: [],
  projectList: [],
  nationalList: [],
  loading: false,
  expertCareerMasterList: {},
};

export const loadData = createAsyncThunk('expertDetailEditPage/loadData', async (_, thunkAPI) => {
  return await Promise.all([
    // getSkillList(),
    getXcareList(),
    // getProjectList(),
    getExpertCareerMasterList(),
  ]);
});

export const loadExpertCareerMasterData = createAsyncThunk(
  'expertDetailEditPage/loadExpertCareerMasterData',
  async (_, thunkAPI) => {
    return await Promise.any([getExpertCareerMasterList()]);
  },
);

export const loadSkillListData = createAsyncThunk(
  'expertDetailEditPage/loadSkillListData',
  async (_, thunkAPI) => {
    return await Promise.any([getSkillList()]);
  },
);

export const loadProjectListData = createAsyncThunk(
  'expertDetailEditPage/loadProjectListData',
  async (_, thunkAPI) => {
    return await Promise.any([getProjectList()]);
  },
);

const slice = createSlice({
  name: 'expertDetailEditPage',
  initialState,
  reducers: {
    setUserInfo(state, action: PayloadAction<UserInfo>) {
      state.user = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadData.pending, (state, action: PayloadAction<any>) => {
      state.loading = true;
    });
    builder.addCase(loadData.rejected, (state, action: PayloadAction<any>) => {
      state.loading = false;
    });
    builder.addCase(loadData.fulfilled, (state, action: PayloadAction<any>) => {
      // state.skillList = action.payload[0].data;
      state.xCareList = action.payload[0].data;
      // state.projectList = action.payload[1].data;
      state.expertCareerMasterList = action.payload[1].data;
      // state.nationalList = action.payload[3].data;

      state.loading = false;
    });
    builder.addCase(loadExpertCareerMasterData.pending, (state, action: PayloadAction<any>) => {
      state.loading = true;
    });
    builder.addCase(loadExpertCareerMasterData.rejected, (state, action: PayloadAction<any>) => {
      state.loading = false;
    });
    builder.addCase(loadExpertCareerMasterData.fulfilled, (state, action: PayloadAction<any>) => {
      state.expertCareerMasterList = action.payload.data;
      state.loading = false;
    });
    builder.addCase(loadSkillListData.pending, (state, action: PayloadAction<any>) => {
      state.loading = true;
    });
    builder.addCase(loadSkillListData.rejected, (state, action: PayloadAction<any>) => {
      state.loading = false;
    });
    builder.addCase(loadSkillListData.fulfilled, (state, action: PayloadAction<any>) => {
      state.skillList = action.payload.data;
      state.loading = false;
    });
    builder.addCase(loadProjectListData.pending, (state, action: PayloadAction<any>) => {
      state.loading = true;
    });
    builder.addCase(loadProjectListData.rejected, (state, action: PayloadAction<any>) => {
      state.loading = false;
    });
    builder.addCase(loadProjectListData.fulfilled, (state, action: PayloadAction<any>) => {
      state.projectList = action.payload.data;
      state.loading = false;
    });
  },
});

export const { actions, reducer: expertDetailEditReducer } = slice;
export const { setUserInfo } = actions;
export default expertDetailEditReducer;
