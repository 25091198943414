import Layout, { PropsLayout } from 'app/components/Layout/Layout';
import Loading from 'app/components/Loading/Loading';
import React, { Fragment, lazy, Suspense, useEffect } from 'react';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { IndexedObject, RootState } from 'types';
import { UserInfo, UserRole } from 'types/userInfo';
import { Epath } from './routesConfig';
import { CircularProgress } from '@mui/material';
import { getCurrentUser } from 'app/pages/Auth/slice/authSlice';
import { useDispatch, useSelector } from 'react-redux';

export type RoutesProps = {
  exact?: boolean;
  path: string;
  component: React.FC<{ history: IndexedObject; location: IndexedObject; match: IndexedObject }>;
  auth?: boolean;
  role?: UserRole;
  routes?: Array<RoutesProps>;
  layout?: React.FC<PropsLayout>;
};

const RenderRoutes = ({
  routes,
  checkAuthLocal,
  currentUser,
  loading,
}: {
  routes: Array<RoutesProps>;
  checkAuthLocal: boolean;
  currentUser: UserInfo;
  loading: boolean;
}) => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  // const {
  //   authenticated,
  //   currentUser,
  //   loading: AuthLoading,
  // } = useSelector((state: RootState) => state.auth);
  const listScreenAllow = [
    Epath.pathXCareExpertList,
    Epath.pathXCareExpertDetail,
    Epath.pathXCareExpertEditDetail,
    Epath.pathXCareClientList,
    Epath.pathXCareClientDetail,
    Epath.pathXCareClientEdit,
    Epath.pathXCareClientLayout,
    Epath.pathXCareProjectLayout,
    Epath.pathXCareProjectList,
    Epath.pathXCareClientProjectDetail,
    Epath.pathXCareClientHearing,
    Epath.pathXCareClientAssignment,
    Epath.pathXCareClientQuotation,
    Epath.pathXcareClientContract,
    Epath.pathXcareClientContractDetail,
    Epath.pathXCareJobTag,
    Epath.pathXCareProjectSetting,
  ];

  useEffect(() => {
    const showPage = listScreenAllow.find((path) => location.pathname.startsWith(path));
    const showPageSetting = location.pathname.endsWith('project-setting');
    if ((showPage || showPageSetting) && currentUser.attributes?.role === 'EX') {
      history.push(Epath.pathNotFoundPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser?.attributes?.role, history, location]);

  useEffect(() => {
    if (
      location.pathname === Epath.pathHomePage ||
      (location.pathname === Epath.pathLogin && checkAuthLocal)
    ) {
      // dispatch(getCurrentUser());
      // console.log('routes currentUser', currentUser);
      if (
        (currentUser?.attributes?.is_info_fulfilled === true &&
          currentUser?.attributes?.logged_in === true) ||
        currentUser?.attributes?.role === 'XC'
      ) {
        history.push(Epath.pathResumePage);
      } else if (currentUser?.attributes?.is_info_fulfilled === false) {
        history.push(Epath.pathExpertOnboarding);
      }
    } else if (checkAuthLocal === false && location.pathname === Epath.pathResumePage) {
      history.push(Epath.pathLogin);
    }
  }, [checkAuthLocal, history, location.pathname]);

  if (loading)
    return (
      // <div className="fixed w-screen h-screen top-0 left-0">
      //   <Loading />
      // </div>
      <div className="flex justify-center items-center h-screen">
        <CircularProgress className="loading" />
      </div>
    );

  return (
    <Suspense
      fallback={
        // <Loading />
        <div className="flex justify-center items-center h-screen">
          <CircularProgress className="loading" />
        </div>
      }
    >
      <Switch>
        {routes.map((route, i) => {
          const Layout = route.layout || Fragment;
          const Component = route.component || <div />;

          if (route.auth && !checkAuthLocal) {
            return <Redirect key={i} to={Epath.pathLogin} />;
          }

          return (
            <Route
              key={i}
              path={route.path}
              exact={!!route.exact}
              render={(props) => {
                return (
                  <Layout>
                    {route.routes ? (
                      <RenderRoutes
                        routes={route.routes}
                        checkAuthLocal={checkAuthLocal}
                        currentUser={currentUser}
                        loading={loading}
                      />
                    ) : (
                      <Component {...props} />
                    )}
                  </Layout>
                );
              }}
            />
          );
        })}
      </Switch>
    </Suspense>
  );
};

export const routes = [
  {
    exact: true,
    path: Epath.pathNotFoundPage,
    component: lazy(() => import('../pages/NotFound/NotFound')),
    role: '*' as UserRole,
  },
  {
    exact: true,
    path: Epath.pathTermOfService,
    component: lazy(() => import('../pages/PolicyService/TermOfService')),
    role: '*' as UserRole,
  },
  {
    exact: true,
    path: '/auth',
    component: lazy(() => import('../pages/Auth/auth')),
    role: '*' as UserRole,
  },
  {
    exact: true,
    path: Epath.pathPrivacyPolicy,
    component: lazy(() => import('../pages/PolicyService/PrivacyPolicy')),
    role: '*' as UserRole,
  },
  {
    exact: true,
    path: Epath.pathLogin,
    component: lazy(() => import('../pages/LoginPage/LoginPage')),
    role: '*' as UserRole,
  },
  {
    exact: true,
    path: Epath.pathXCareLogin,
    component: lazy(() => import('../pages/LoginPage/LoginPage')),
    role: '*' as UserRole,
  },
  {
    exact: true,
    path: Epath.pathSignUp,
    component: lazy(() => import('../pages/SignUpPage/SignUpPage')),
    role: '*' as UserRole,
  },
  {
    exact: true,
    path: Epath.pathExpertOnboarding,
    component: lazy(() => import('../pages/ExpertOnboardingPage/ExpertOnboardingPage')),
    role: '*' as UserRole,
  },
  {
    exact: true,
    path: Epath.pathSignUpPassword,
    component: lazy(() => import('../pages/SignUpPasswordPage/SignUpPasswordPage')),
    role: '*' as UserRole,
  },
  {
    exact: true,
    path: Epath.pathForgetPassword,
    component: lazy(() => import('../pages/ForgotPasswordPage/ForgotPasswordPage')),
    role: '*' as UserRole,
  },
  {
    exact: true,
    path: Epath.pathResetPassword,
    component: lazy(() => import('../pages/ResetPasswordPage/ResetPasswordPage')),
    role: '*' as UserRole,
  },
  {
    expect: true,
    path: Epath.pathInquiry,
    component: lazy(() => import('../pages/InquiryPage/InquiryPage')),
    role: '*' as UserRole,
  },
  {
    expect: true,
    path: Epath.pathSettingPassword,
    component: lazy(() => import('../pages/SettingPassword/SettingPassword')),
    role: '*' as UserRole,
  },
  {
    expect: true,
    path: Epath.pathPreEntry,
    component: lazy(() => import('../pages/SettingPassword/ExpertPreEntryForm/ExpertPreEntryForm')),
    role: '*' as UserRole,
  },
  {
    path: '*',
    layout: Layout,
    component: () => <Redirect to={Epath.pathResumePage} />,
    role: '*' as UserRole,
    routes: [
      {
        exact: false,
        path: Epath.pathXCareClientLayout,
        component: lazy(() => import('../components/Layout/LayoutClientPage/LayoutClientPage')),
        auth: true,
        role: 'XC' as UserRole,
      },
      {
        exact: true,
        path: Epath.pathHomePage,
        component: lazy(() => import('../pages/HomePage/HomePage')),
        auth: true,
        role: '*' as UserRole,
      },
      {
        exact: true,
        path: Epath.pathChangePassword,
        component: lazy(() => import('../pages/ChangePasswordPage/ChangePasswordPage')),
        auth: true,
        role: 'EX' as UserRole,
      },
      {
        exact: true,
        path: Epath.pathResumePage,
        component: lazy(() => import('../pages/ResumePage/ResumePage')),
        auth: true,
        role: 'EX' as UserRole,
      },
      {
        exact: true,
        path: Epath.pathOpManagementPage,
        component: lazy(() => import('../pages/OpManagementPage/OpManagementPage')),
        auth: true,
        role: 'EX' as UserRole,
      },
      {
        exact: true,
        path: Epath.pathXCareExpertList,
        component: lazy(() => import('../pages/xCare/ExpertSection/ExpertListPage/ExpertListPage')),
        auth: true,
        role: 'XC' as UserRole,
      },
      {
        exact: true,
        path: Epath.pathXCareProjects,
        component: lazy(() => import('../pages/xCare/ProjectManagement/ProjectList')),
        auth: true,
        role: 'XC' as UserRole,
      },
      {
        exact: true,
        path: Epath.pathXCareExpertDetail,
        component: lazy(
          () => import('../pages/xCare/ExpertSection/ExpertDetailPage/ExpertDetailPage'),
        ),
        auth: true,
        role: 'XC' as UserRole,
      },
      {
        exact: true,
        path: Epath.pathXCareExpertEditDetail,
        component: lazy(
          () => import('../pages/xCare/ExpertSection/ExpertDetailEditPage/ExpertDetailEditPage'),
        ),
        auth: true,
        role: 'XC' as UserRole,
      },
      {
        exact: false,
        path: Epath.pathXCareClientList,
        component: lazy(
          () => import('../pages/xCare/ContractSection/ClientListPage/ClientListPage'),
        ),
        auth: true,
        role: 'XC' as UserRole,
      },
      {
        expect: true,
        path: Epath.pathXCareProjectList,
        component: lazy(
          () => import('../pages/xCare/ContractSection/ProjectListPage/ProjectListPage'),
        ),
        role: 'XC' as UserRole,
      },
      {
        exact: false,
        path: Epath.pathXCareProjectLayout,
        component: lazy(() => import('../components/Layout/LayoutProjectPage/LayoutProjectPage')),
        auth: true,
        role: 'XC' as UserRole,
      },
      {
        exact: true,
        path: '*',
        component: () => <Redirect to={Epath.pathNotFoundPage} />,
        role: '*' as UserRole,
      },
    ],
  },
];

export default RenderRoutes;
