import { isEmptyObject } from 'app/helpers/common';
import { DEFAULT_LANGUAGE, languages } from 'locales/i18n';
import { UserInfo } from 'types/userInfo';

const keyAuthToken: string = 'token';
const keyRefreshToken: string = 'refreshToken';
const keyCurrentUser: string = 'localUser';

export const getLocalItem = (name: string) => {
  const item = localStorage.getItem(name);
  if (!item) return null;
  return JSON.parse(item);
};

export const setLocalItem = (name: string, value: any) => {
  localStorage.setItem(name, JSON.stringify(value));
};

export const removeLocalItem = (name: string) => {
  localStorage.removeItem(name);
};

//
export const getRefreshToken = (): string => localStorage.getItem(keyRefreshToken) ?? '';
export const getAccessToken = (): string | void => localStorage.getItem(keyAuthToken) ?? '';

export const saveCurrentUserToLS = (user: UserInfo) => {
  if (user.id) {
    localStorage.setItem(keyCurrentUser, JSON.stringify(user));
  } else {
    localStorage.removeItem(keyCurrentUser);
  }
};

export const getCurrentFromLS = (): UserInfo => {
  let data = {};
  const userLS = localStorage.getItem(keyCurrentUser);
  if (userLS) {
    const tempData = JSON.parse(userLS);
    if (tempData) {
      data = tempData;
    }
  }
  return data;
};

//change language
// Save language to localStorage
export const saveLanguage = (language: string) => {
  if (languages.includes(language)) {
    localStorage.setItem('i18nextLng', language);
  } else {
    localStorage.setItem('i18nextLng', DEFAULT_LANGUAGE);
  }
};
export const getLanguage = () => {
  const language = localStorage.getItem('i18nextLng');
  return language && languages.includes(language) ? language : DEFAULT_LANGUAGE;
};

export const checkUserInfo =
  !isEmptyObject(getCurrentFromLS()) && (getAccessToken() && getRefreshToken()) !== '';
