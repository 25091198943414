import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import projectApi from 'app/axios/api/projectApi';
import { RootState } from 'types';
import {
  TGetProjectList,
  TProjectAgreement,
  TProjectInList,
  TTaskFileQueryParams,
} from 'types/project';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { ProjectListPageState, TCreateProject } from './projectListPageTypes';

const initialState: ProjectListPageState = {
  params: {},
  listTaskFile: [],
  projectList: [],
  loading: false,
};

export const getProjectList = createAsyncThunk(
  'projectListPage/getProjectList',
  async (params: TGetProjectList, thunkAPI) => {
    thunkAPI.dispatch(setParamsGetProjectList(params));
    const res = await projectApi.getProjectList(params);
    return res.data as TProjectInList;
  },
);

export const loadDataProjectPage = createAsyncThunk(
  'projectListPage/loadDataProjectPage',
  async (params: TGetProjectList, { dispatch }) => {
    dispatch(getProjectList(params));
  },
);

export const deleteProject = createAsyncThunk(
  'projectListPage/deleteProject',
  async (id: string, thunkAPI) => {
    thunkAPI.dispatch(setLoadingProject(true));
    await projectApi
      .deleteProject(id)
      .then(() => {
        thunkAPI.dispatch(removeProjectFormList(id));
      })
      .catch(() => {});
    thunkAPI.dispatch(setLoadingProject(false));
  },
);

export const updateProject = createAsyncThunk(
  'projectListPage/updateProject',
  async (data: FormData, thunkAPI) => {
    thunkAPI.dispatch(setLoadingProject(true));
    await projectApi
      .updateProject(data)
      .then((res) => {
        thunkAPI.dispatch(updateProjectFromList(res.data as TProjectInList));
      })
      .catch(() => {});
    thunkAPI.dispatch(setLoadingProject(false));
  },
);

export const createProject = createAsyncThunk(
  'projectListPage/createProject',
  async (data: TCreateProject, thunkAPI) => {
    thunkAPI.dispatch(setLoadingProject(true));
    await projectApi
      .createProject(data)
      .then(() => {
        const { params } = (thunkAPI.getState() as RootState).projectListPage;
        thunkAPI.dispatch(getProjectList(params));
      })
      .catch(() => {});
    thunkAPI.dispatch(setLoadingProject(false));
  },
);

export const updateProjectAgreement = createAsyncThunk(
  'projectListPage/updateProjectAgreement',
  async (data: TProjectAgreement, thunkAPI) => {
    thunkAPI.dispatch(setLoadingProject(true));
    const formData = new FormData();
    Object.keys(data).forEach((key) => formData.append(key, data[key]));
    await projectApi
      .updateProjectAgreement(formData)
      .then(() => {
        const { params } = (thunkAPI.getState() as RootState).projectListPage;
        thunkAPI.dispatch(getProjectList(params));
      })
      .catch(() => {});
    thunkAPI.dispatch(setLoadingProject(false));
  },
);

export const getListTaskFile = createAsyncThunk(
  'projectListPage/getListTaskFile',
  async (params: TTaskFileQueryParams) => {
    const res = await projectApi.getProjectTaskFile(params);
    return res.data.data;
  },
);

const slice = createSlice({
  name: 'projectListPage',
  initialState,
  reducers: {
    setParamsGetProjectList(state, action: PayloadAction<TGetProjectList>) {
      state.params = action.payload;
    },
    removeProjectFormList(state, action: PayloadAction<string>) {
      state.projectList = state.projectList.filter((c) => c.id !== action.payload);
    },
    setLoadingProject(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    updateProjectFromList(state, action: PayloadAction<TProjectInList>) {
      state.projectList = state.projectList.map((c) =>
        c.id === action.payload.id ? action.payload : c,
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getProjectList.pending, (state: ProjectListPageState) => {
      state.loading = true;
    });
    builder.addCase(getProjectList.rejected, (state: ProjectListPageState) => {
      state.loading = false;
      state.projectList = [];
    });
    builder.addCase(
      getProjectList.fulfilled,
      (state: ProjectListPageState, action: PayloadAction<any>) => {
        state.projectList = action.payload.data;
        state.loading = false;
      },
    );

    //list task file
    builder.addCase(getListTaskFile.pending, (state: ProjectListPageState) => {
      state.loading = true;
    });
    builder.addCase(getListTaskFile.rejected, (state: ProjectListPageState) => {
      state.loading = false;
      state.listTaskFile = [];
    });
    builder.addCase(
      getListTaskFile.fulfilled,
      (state: ProjectListPageState, action: PayloadAction<any>) => {
        state.listTaskFile = action.payload;
        state.loading = false;
      },
    );
  },
});

export const { actions, reducer: projectListPageReducer } = slice;
export const {
  removeProjectFormList,
  setLoadingProject,
  updateProjectFromList,
  setParamsGetProjectList,
} = actions;
export default projectListPageReducer;
