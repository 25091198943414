import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import projectApi from 'app/axios/api/projectApi';
import { TProjectTaskQueryParams, ListProjectTask } from 'types/project';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { GanttChartState } from './ganttChartTypes';

const initialState: GanttChartState = {
  listProjectTask: [],
  totalTask: 0,
  loading: false,
};

export const getListProjectTask = createAsyncThunk(
  'ganttChartPage/getListProjectTask',
  async (params: TProjectTaskQueryParams, thunkAPI) => {
    const data = await projectApi.getProjectTask(params);
    return data.data.data as ListProjectTask;
  },
);

export const saveTotalTask = createAsyncThunk(
  'ganttChartPage/saveTotalTask',
  async (total: number, thunkAPI) => {
    return total;
  },
);

const slice = createSlice({
  name: 'ganttChartPage',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getListProjectTask.pending, (state, action: PayloadAction<any>) => {
      state.loading = true;
    });
    builder.addCase(getListProjectTask.rejected, (state, action: PayloadAction<any>) => {
      state.loading = false;
    });
    builder.addCase(getListProjectTask.fulfilled, (state, action: PayloadAction<any>) => {
      state.listProjectTask = action.payload;
      state.loading = false;
    });

    builder.addCase(saveTotalTask.fulfilled, (state, action: PayloadAction<any>) => {
      state.totalTask = action.payload;
      state.loading = false;
    });
  },
});

export const { actions, reducer: ganttChartReducer } = slice;
// export const { setUserInfo } = actions;
export default ganttChartReducer;
