import { Alert, Snackbar, SnackbarOrigin } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'types';
import { closeNoti } from './slice/notificationSlice';

const defaultPosition: SnackbarOrigin = {
  vertical: 'bottom',
  horizontal: 'left',
};

interface Props {
  className?: string;
  position?: SnackbarOrigin;
}

const Notification = (props: Props) => {
  const { className, position = defaultPosition } = props;
  const { active, duration, message, type } = useSelector((state: RootState) => state.notification);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(closeNoti());
  };

  return (
    <Snackbar
      open={active}
      onClose={handleClose}
      autoHideDuration={duration}
      anchorOrigin={position}
      className={className}
    >
      <Alert onClose={handleClose} severity={type} className={'!min-w-[400px]'}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default Notification;
