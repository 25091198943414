import { CognitoUserPool } from 'amazon-cognito-identity-js';

const poolData = {
  // UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID ?? '',
  // ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID ?? '',
  // UserPoolId: 'ap-northeast-1_NJwrIxNuS',
  // ClientId: '7nk1f7536dq4bf0hvlf1j5p5rv',
  UserPoolId: 'ap-northeast-1_ULucfESSV',
  ClientId: 'i4o126oo3hrqor40i5d61lm1i',
};
const Pool = new CognitoUserPool(poolData);

export default Pool;
