import * as _ from 'lodash';
class InvalidUrlError extends TypeError {}
/*
  This Obj is using for invalidate cache in swr
  'test' will invalidate 'test' and 'test/123' or 'hello'
  'test/123' will invalidate 'test' and 'test/123' but not 'test/1234' or 'hello'
*/
export class UrlObj {
  model: string = '';
  query: string | undefined = undefined;
  constructor(url: string) {
    const matchResult = UrlObj.matchURL(url);
    if ('modelName' in matchResult) {
      this.model = matchResult.modelName;
      this.query = matchResult.query;
    }
  }
  static matchURL(url: string) {
    const regex = /^([\w-]+)(?:\/([\w-]+))?(?:\?([\w=&%-_]+))?/;

    // Use the match() method to extract the capture groups
    const matches = url.match(regex);

    // Extract the capture groups into separate variables
    if (!matches) {
      return new InvalidUrlError('The url is invalid');
    }
    const modelName = matches[1];
    const query = matches[2];
    const param = matches[3];

    // Print the variables to check the output
    return {
      modelName,
      query,
      param,
    };
  }

  willInvalidate(otherUrl: UrlObj) {
    if (this.model !== otherUrl.model) return false;
    if (_.isUndefined(this.query) || _.isUndefined(otherUrl.query)) return true;
    return this.query === otherUrl.query;
  }
}
