import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import jobTagApi from 'app/axios/api/jobTagApi';
import { RootState } from 'types';
import { TJobTagInList } from 'types/jobTag';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { JobTagPageState, TCreateJobTag, TGetJobTagList, TUpdateJobTag } from './jobTagTypes';

const initialState: JobTagPageState = {
  params: {},
  jobTagList: [],
  loading: false,
};

export const getJobTagList = createAsyncThunk(
  'jobTag/getJobTagList',
  async (params: TGetJobTagList, thunkAPI) => {
    thunkAPI.dispatch(setParamsGetJobTagList(params));
    const res = await jobTagApi.getJobTagList(params);
    return res.data as TJobTagInList;
  },
);

export const deleteJobTag = createAsyncThunk(
  'jobTag/deleteJobTag',
  async (id: string, thunkAPI) => {
    thunkAPI.dispatch(setLoadingJobTag(true));
    await jobTagApi
      .deleteJobTag(id)
      .then(() => {
        thunkAPI.dispatch(removeJobTagFormList(id));
      })
      .catch(() => {});
    thunkAPI.dispatch(setLoadingJobTag(false));
  },
);

export const updateJobTag = createAsyncThunk(
  'jobTag/updateJobTag',
  async (data: TUpdateJobTag, thunkAPI) => {
    thunkAPI.dispatch(setLoadingJobTag(true));
    await jobTagApi
      .updateJobTag(data)
      .then((res) => {
        thunkAPI.dispatch(
          updateJobTagFromList((res.data as unknown as { data: TJobTagInList }).data),
        );
      })
      .catch(() => {});
    thunkAPI.dispatch(setLoadingJobTag(false));
  },
);

export const createJobTag = createAsyncThunk(
  'jobTag/createJobTag',
  async (data: TCreateJobTag, thunkAPI) => {
    thunkAPI.dispatch(setLoadingJobTag(true));
    await jobTagApi
      .createJobTag(data)
      .then(() => {
        const { params } = (thunkAPI.getState() as RootState).jobTag;
        thunkAPI.dispatch(getJobTagList(params));
      })
      .catch(() => {});
    thunkAPI.dispatch(setLoadingJobTag(false));
  },
);

const slice = createSlice({
  name: 'jobTag',
  initialState,
  reducers: {
    setParamsGetJobTagList(state, action: PayloadAction<TGetJobTagList>) {
      state.params = action.payload;
    },
    removeJobTagFormList(state, action: PayloadAction<string>) {
      state.jobTagList = state.jobTagList.filter((c) => c.id !== action.payload);
    },
    setLoadingJobTag(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    updateJobTagFromList(state, action: PayloadAction<TJobTagInList>) {
      state.jobTagList = state.jobTagList.map((c) =>
        c.id === action.payload.id ? action.payload : c,
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getJobTagList.pending, (state: JobTagPageState) => {
      state.loading = true;
    });
    builder.addCase(getJobTagList.rejected, (state: JobTagPageState) => {
      state.loading = false;
      state.jobTagList = [];
    });
    builder.addCase(
      getJobTagList.fulfilled,
      (state: JobTagPageState, action: PayloadAction<any>) => {
        state.jobTagList = action.payload.data;
        state.loading = false;
      },
    );
  },
});

export const { actions, reducer: jobTagReducer } = slice;
export const {
  removeJobTagFormList,
  setLoadingJobTag,
  updateJobTagFromList,
  setParamsGetJobTagList,
} = actions;
export default jobTagReducer;
