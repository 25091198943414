import downloadFileAPI from 'app/axios/api/downloadFile';
import { axiosClient } from 'app/axios/axiosClient';
import { t } from 'i18next';
import { translations } from 'locales/translations';
import { forEach, map, zip } from 'lodash';
import moment from 'moment';
import { HistoryObj } from 'types/history';
import { HistoryParameter, ProjectTask } from 'types/projectTask';
import { Skill } from 'types/skill';
import { DataReturn, MaybeReadonly } from 'types/types';
import { PrimaryContactRoles, UserInfo } from 'types/userInfo';
import { ListTaskFileResponse, Project, TClientInProject } from './../types/project';
import {
  ALL_DAYS,
  ALL_DAYS_JA,
  AVAILABLE_ONLY_HOLIDAY,
  AVAILABLE_ONLY_HOLIDAY_JA,
  CONTACT_FACEBOOK,
  CONTACT_LINKEDIN,
  CONTACT_OTHER,
  HOUR_10,
  HOUR_10_JA,
  HOUR_20,
  HOUR_20_JA,
  HOUR_30,
  HOUR_30_JA,
  HOUR_40,
  HOUR_40_JA,
  HOUR_80,
  HOUR_80_JA,
  HOUR_81,
  HOUR_81_JA,
  STATUS_PROJECT,
  WEEKDAY_DAY,
  WEEKDAY_DAY_JA,
  WEEKDAY_NIGHTS,
  WEEKDAY_NIGHTS_JA,
  WEEKDAY_NIGHTS_WEEKEND_AND_HOLIDAYS,
  WEEKDAY_NIGHTS_WEEKEND_AND_HOLIDAYS_JA,
  WEEKDAY_OTHER,
  WEEKDAY_OTHER_JA,
  WEEKEND_AND_HOLIDAYS_ONLY,
  WEEKEND_AND_HOLIDAYS_ONLY_JA,
  WORK_WEEKDAY_DAY,
  WORK_WEEKDAY_DAY_JA,
} from './constants';
import { DEFAULT_FORMAT_DATE_TIME } from './date';
import { getLanguage } from './localStorage';

export const getFileExtension: (filename: string) => string = (filename: string) => {
  return filename.split('.').pop()?.toLowerCase() ?? '';
};

export function getFileNameFromUrl(url) {
  const urlRegex = /^(?:https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
  if (!urlRegex.test(url)) {
    return '';
  }

  try {
    const pathname = new URL(url).pathname;
    const filename = pathname.split('/').pop();
    const nameWithoutExt = filename?.split('.').join('.');
    return decodeURIComponent(nameWithoutExt || '');
  } catch (error) {
    return '';
  }
}

export const mapUserSkill: (user: UserInfo, skillList: Array<Skill>) => string[] = (
  user: UserInfo,
  skillList: Array<Skill>,
) => {
  return (
    user.relationships?.skill?.data?.map((skill) => {
      const currentSkill = skillList.find((s) => s.id.toString() === skill.id.toString());
      if (currentSkill) {
        return currentSkill.attributes.name;
      } else {
        return skill.id;
      }
    }) ?? []
  );
};

export const mapUserSkillWithOnlyId: (skillId: number[], skillList: Array<Skill>) => string[] = (
  skillId: number[],
  skillList: Array<Skill>,
) => {
  return (
    skillId.map((id) => {
      const currentSkill = skillList.find((s) => s.id.toString() === id.toString());
      if (currentSkill) {
        return currentSkill.attributes.name;
      } else {
        return '';
      }
    }) ?? []
  );
};

export const getUserName = (user: UserInfo) => {
  return `${user?.attributes?.user_other_info?.first_name}`;
};

export const getFurigana = (user: UserInfo) => {
  return user?.attributes?.name_furigana ?? '';
};

export const getFirstContract = (user: UserInfo) => {
  return user?.attributes?.priority_contact_status;
};

export const getRegistrationStatusOptions = () => {
  return [
    {
      value: 1,
      label: t(translations.EXPERT.STATUS_TEMPORARY),
      labelSm: t(translations.EXPERT.STATUS_TEMPORARY),
    },
    {
      value: 2,
      label: t(translations.EXPERT.STATUS_OFFICIAL_RECORD_AVAILABLE),
      labelSm: t(translations.EXPERT.STATUS_OFFICIAL_RECORD_AVAILABLE_SM),
    },
    {
      value: 3,
      label: t(translations.EXPERT.STATUS_OFFICIAL_RECORD_UNAVAILABLE),
      labelSm: t(translations.EXPERT.STATUS_OFFICIAL_RECORD_UNAVAILABLE),
    },
    {
      value: 4,
      label: t(translations.EXPERT.STATUS_WITHDRAWAL),
      labelSm: t(translations.EXPERT.STATUS_TEMPORARY),
    },
    {
      value: 5,
      label: t(translations.EXPERT.STATUS_NOT_QUALIFIED),
      labelSm: t(translations.EXPERT.STATUS_NOT_QUALIFIED_SM),
    },
  ];
};

export const getEnglishSkillOptions = () => {
  return [
    { value: 'Native', label: 'Native' },
    { value: 'Fluent', label: 'Fluent' },
    { value: 'Business', label: 'Business' },
    { value: 'Basic', label: 'Basic' },
    { value: 'None', label: 'None' },
  ];
};

export const getUserGenres = (user: UserInfo) => {
  const language = getLanguage();
  const genreList: string[] = [];
  if (user.attributes?.genre_medicine)
    genreList.push(language === 'en' ? 'Pharmaceuticals' : '医薬品');
  if (user.attributes?.genre_instruments)
    genreList.push(language === 'en' ? 'Medical instruments' : '医療機器');
  if (user.attributes?.genre_diagnostics)
    genreList.push(language === 'en' ? 'In-vitro diagnostics' : '体外診断用医薬品');
  if (user.attributes?.genre_regenerative)
    genreList.push(language === 'en' ? 'Regenerative medicine' : '再生医療');
  if (user.attributes?.genre_other) genreList.push(language === 'en' ? 'Other' : 'その他');

  return genreList;
};

export const getUserWorkday = (user: UserInfo) => {
  const workdayList: string[] = [];
  const language = getLanguage();

  if (language === 'ja') {
    if (user.attributes?.available_only_holiday) workdayList.push(WEEKEND_AND_HOLIDAYS_ONLY_JA);
    if (user.attributes?.contact_weekday) workdayList.push(WEEKDAY_NIGHTS_WEEKEND_AND_HOLIDAYS_JA);
    if (user.attributes?.work_weekday_day) workdayList.push(ALL_DAYS_JA);
  } else {
    if (user.attributes?.available_only_holiday) workdayList.push(WEEKEND_AND_HOLIDAYS_ONLY);
    if (user.attributes?.contact_weekday) workdayList.push(WEEKDAY_NIGHTS_WEEKEND_AND_HOLIDAYS);
    if (user.attributes?.work_weekday_day) workdayList.push(ALL_DAYS);
  }

  return workdayList;
};

export const getUserWorkdayV2 = (user: UserInfo) => {
  const workdayList: string[] = [];
  const language = getLanguage();

  if (language === 'ja') {
    if (user.attributes?.weekday_day) workdayList.push(WEEKDAY_DAY_JA);
    if (user.attributes?.weekday_nights) workdayList.push(WEEKDAY_NIGHTS_JA);
    if (user.attributes?.available_only_holiday) workdayList.push(AVAILABLE_ONLY_HOLIDAY_JA);
    if (user.attributes?.work_weekday_day) workdayList.push(WORK_WEEKDAY_DAY_JA);
    if (user.attributes?.weekday_other) workdayList.push(WEEKDAY_OTHER_JA);
  } else {
    if (user.attributes?.weekday_day) workdayList.push(WEEKDAY_DAY);
    if (user.attributes?.weekday_nights) workdayList.push(WEEKDAY_NIGHTS);
    if (user.attributes?.available_only_holiday) workdayList.push(AVAILABLE_ONLY_HOLIDAY);
    if (user.attributes?.work_weekday_day) workdayList.push(WORK_WEEKDAY_DAY);
    if (user.attributes?.weekday_other) workdayList.push(WEEKDAY_OTHER);
  }

  return workdayList;
};

export const getUserPrimaryContact = (user: UserInfo) => {
  switch (user.attributes?.primary_contact) {
    case PrimaryContactRoles.Facebook:
      return CONTACT_FACEBOOK;
    case PrimaryContactRoles.LinkedIn:
      return CONTACT_LINKEDIN;
    case PrimaryContactRoles.Others:
      return CONTACT_OTHER;
    default:
      return '-';
  }
};

export function convertPositionTaskMove(position: string) {
  switch (position) {
    case 'middleSegment':
      return 'middle';
    case 'topSegment':
      return 'top';
    default:
      return 'bottom';
  }
}

export function getStatusProject(startDate?: Date) {
  const language = getLanguage();
  const currentDate = new Date();
  if (!startDate || startDate > currentDate) {
    return language === 'en' ? 'Not started yet' : '非稼働';
  } else {
    return language === 'en' ? 'Doing' : '稼働';
  }
}

export function getProductCategoryName(productCategory: string) {
  const language = getLanguage();
  switch (productCategory) {
    case 'ME':
      return language === 'ja' ? '医薬品' : 'Pharmaceuticals';
    case 'IT':
      return language === 'ja' ? '医療器具' : 'Medical instruments';
    case 'RG':
      return language === 'ja' ? '再生医療' : 'Regenerative medicine';
    case 'OT':
      return language === 'ja' ? 'その他' : 'Other';
    default:
      return '';
  }
}

const MAX_LENGTH = 30;

export function truncateText(str: string, maxLength = MAX_LENGTH) {
  const extension = str.split('.').pop();
  const truncatedFilename = extension && str.substring(0, str.length - extension.length - 1);
  if (truncatedFilename && truncatedFilename.length > maxLength) {
    return truncatedFilename.substring(0, maxLength) + extension;
  } else if (str.length > maxLength) {
    return str.substring(0, maxLength);
  }
  return str;
}

export function truncateArray(arr, limit) {
  if (arr.length <= limit) {
    return arr;
  } else {
    return arr.slice(-3);
  }
}

interface Option<T extends string | number> {
  value: T;
  label: string;
}

export function optionToListOption<T extends string | number>(
  arrayValue: Option<T>[] | readonly Option<T>[],
  callback: (arg: T) => void,
) {
  return map(arrayValue, (value) => ({
    title: value.label,
    event: () => callback(value.value),
  }));
}

export type Over = 'none' | 'top' | 'bottom' | 'middle';

export function getNearestPosition(
  target: HTMLElement,
  clientY: number,
  availablePosition = ['top', 'bottom', 'middle'] as MaybeReadonly<Over[]>,
) {
  const box = target.getBoundingClientRect();
  const PositionArr = [
    {
      name: 'top',
      value: box.y,
    },
    {
      name: 'bottom',
      value: box.y + box.height,
    },
    {
      name: 'middle',
      value: box.y + box.height / 2,
    },
  ] as const;
  const availablePositionArr = PositionArr.filter((pos) => availablePosition.includes(pos.name));
  return availablePositionArr.reduce(
    (closet, pos) => {
      const range = Math.abs(pos.value - clientY);
      if (range > closet.range) return closet;
      return { name: pos.name, range };
    },
    { name: '', range: Number.POSITIVE_INFINITY },
  ).name as Over;
}

export const convertedDataGanttChart = (data?: DataReturn<ProjectTask[]>) => {
  return data?.map((task) => {
    // Extract information from API data
    const taskID = task.id;
    const taskName = task.attributes.name;
    const status = task.attributes.status;
    const assignee = task.attributes.list_person;
    let startDate = task.attributes.start_date;
    let endDate = task.attributes.end_date;

    // Check and update startDate and endDate
    if (!startDate && endDate) {
      startDate = '';
      endDate = '';
    }

    const operatingTime = task.attributes.operating_time;
    const estimateTime = task.attributes.estimate_time;
    const progress = task.attributes.progress;
    const isParent = task.attributes.children.length > 0;
    const subtasks = task.attributes.children.map((childTask) => {
      // Extract information from child Task
      const subtaskID = childTask.id;
      const subtaskName = childTask.name;
      let subtaskStartDate = childTask.start_date;
      let subtaskEndDate = childTask.end_date;
      const subtaskStatus = childTask.status;
      const subtaskAssignee = childTask.list_person;
      const subtaskOperatingTime = childTask.operating_time;
      const subtaskEstimateTime = childTask.estimate_time;
      const subtaskProgress = childTask.progress;

      // Check and update subtaskStartDate and subtaskEndDate
      if (!subtaskStartDate && subtaskEndDate) {
        subtaskStartDate = '';
        subtaskEndDate = '';
      }

      // Return new object for subtask
      return {
        TaskID: subtaskID,
        TaskName: subtaskName,
        StartDate: subtaskStartDate ? new Date(subtaskStartDate) : null,
        EndDate: subtaskEndDate ? new Date(subtaskEndDate) : null,
        Status: subtaskStatus,
        Budget: subtaskEstimateTime ?? '0:00',
        Actual: subtaskOperatingTime ?? '0:00',
        Progress: subtaskProgress,
        Assignee: subtaskAssignee,
        isParent: false,
      };
    });
    // Return new object for task
    return {
      TaskID: taskID,
      TaskName: taskName,
      Status: status,
      Assignee: assignee,
      StartDate: startDate ? new Date(startDate) : null,
      EndDate: endDate ? new Date(endDate) : null,
      Budget: estimateTime ?? '0:00',
      Actual: operatingTime ?? '0:00',
      Progress: progress,
      isParent: isParent,
      subtasks: subtasks,
    };
  });
};

// function drag&drop quote/task in project management
function getObjectById(id: string, formatData): ProjectTask & { id: string } {
  for (const obj of formatData as (ProjectTask & { id: string })[]) {
    if (obj.id === id) {
      return obj;
    }
    const childObj = obj.children.find((child) => child.id === id);
    if (childObj) {
      return obj;
    }
  }
  throw TypeError();
}
function getListObj(
  id: string,
  formatData,
): {
  list: (ProjectTask & { id: string })[];
  id: string;
  order: number;
} {
  if (!formatData) throw TypeError();
  for (const obj of formatData as (ProjectTask & { id: string })[]) {
    if (obj.id === id) {
      return { list: formatData, id: '', order: obj.order };
    }
    const childObj = obj.children.find((child) => child.id === id);
    if (childObj) {
      return { list: obj.children, id: obj.id, order: childObj.order };
    }
  }
  throw TypeError();
}

/**
 *
 * @param fromId from the task ID
 * @param toId to the task ID
 * @param position Drag and drop position
 * @param formatData
 * @returns
 */
export function moveFunctionHelper(
  fromId: string,
  toId: string,
  position: Exclude<Over, 'none'>,
  formatData: any,
) {
  function getMoveObj(toId: string, position: Exclude<Over, 'none'>, formatData) {
    if (position === 'middle') {
      const children = getObjectById(toId, formatData).children;
      return {
        id: toId,
        order: children.length ? Math.max(...children.map((c) => c.order)) + 1 : 0,
      };
    }
    const listObj = getListObj(toId, formatData);
    const toOrder = listObj.order;
    let otherOrder;
    if (position === 'top') {
      const tasksBefore = listObj.list.filter((i) => i.order < toOrder);
      otherOrder = tasksBefore.length ? Math.max(...tasksBefore.map((t) => t.order)) : toOrder - 2;
    } else {
      const tasksAfter = listObj.list.filter((i) => i.order > toOrder);
      otherOrder = tasksAfter.length ? Math.min(...tasksAfter.map((t) => t.order)) : toOrder + 2;
    }
    return { id: listObj.id, order: (otherOrder + toOrder) / 2 };
  }
  const result = getMoveObj(toId, position, formatData);
  return axiosClient.patch(`api/projectTask/${fromId}`, {
    order: result.order,
    parent: result.id,
  });
}

export function StringtoParam(arg: string) {
  const index = arg.indexOf('__');
  return {
    key: arg.slice(0, index),
    action: arg.slice(index + 2),
  };
}
export function twoLetterNumber(arg: number) {
  return arg < 10 ? `0${arg}` : `${arg}`;
}

//convert data list assign
export function convertListAssign(data) {
  return data.map((item) => {
    const {
      id,
      attributes: {
        expert_info: {
          avatar_image,
          user_other_info: { first_name, last_name },
        },
      },
    } = item;
    return { id, avatar_image, first_name, last_name };
  });
}

//convert data list assign
export function convertInfoFileProject(data) {
  return data.map((item) => {
    const {
      id,
      attributes: {
        expert_info: { avatar, first_name, last_name },
      },
    } = item;
    return { id, avatar, first_name, last_name };
  });
}

// convert data project file
export function convertDataListFile(obj: ListTaskFileResponse[]) {
  // task_file
  const taskFileData = obj?.map((file) => ({
    id: file.id,
    name: file.attributes.file,
    created_date: file.attributes.created_at,
    creator: `${file.attributes.user.first_name}`,
    location: file.attributes.project_task_detail?.name,
  }));

  return [...(taskFileData || [])];
}

//Dowload file
export function downloadURL(url, name) {
  var link = document.createElement('a');
  // If you don't know the name or want to use
  // the webserver default set name = ''
  link.setAttribute('download', name);
  link.href = url;
  link.target = '_blank';
  document.body.appendChild(link);
  link.click();
  link.remove();
}

/**
 * function rounds to the second decimal
 * @param num number
 * @returns 50 -> 50 % , 49,511 -> 49,51 %
 */
export function roundPercentage(num: number) {
  num = Math.round(num * 100) / 100;
  if (Number.isInteger(num)) {
    return num.toString() + ' %';
  } else {
    return num.toFixed(2) + ' %';
  }
}
export function convertToTitleCase(str) {
  // split the string by the capital letters
  const words = str.split(/(?=[A-Z])/);
  // capitalize the first letter of each word and join them with a space
  return words.map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
}

export function getOptionValue(value: string, options: { label: string; id: string }[]) {
  const option = options.find((option) => option.id === value);

  if (option) {
    return option;
  }

  return undefined;
}

export function getMaxLength(s: string, num = 12) {
  if (s.length < num) return s;
  return `${s.slice(0, num)}...`;
}

export function getClientProjectStatus(project: TClientInProject) {
  // const language = getLanguage();
  // const currentDate = new Date();
  // const startDate =
  //   project.attributes?.projectagreement &&
  //   project.attributes.projectagreement.start_date &&
  //   new Date(project.attributes.projectagreement.start_date);

  // if (!startDate || startDate > currentDate) {
  //   return language === 'en' ? 'Not started yet' : '非稼働';
  // } else {
  //   return language === 'en' ? 'Doing' : '稼働';
  // }

  const currentValue = Object.entries(STATUS_PROJECT).find(
    ([key, value]) => value === project?.project_status,
  )?.[0];

  return t(translations.PROJECTS[currentValue ?? 'NOT_STARTED_YET']);
}

export function getProjectStatus(project: Project) {
  // const language = getLanguage();
  // const currentDate = new Date();
  // const startDate =
  //   project.attributes?.projectagreement &&
  //   project.attributes.projectagreement.start_date &&
  //   new Date(project.attributes.projectagreement.start_date);

  // if (!startDate || startDate > currentDate) {
  //   return language === 'en' ? 'Not started yet' : '非稼働';
  // } else {
  //   return language === 'en' ? 'Doing' : '稼働';
  // }

  const currentValue = Object.entries(STATUS_PROJECT).find(
    ([key, value]) => value === project?.attributes.project_status,
  )?.[0];

  return t(translations.PROJECTS[currentValue ?? 'NOT_STARTED_YET']);
}
type Truthy<T> = T extends false | '' | 0 | null | undefined ? never : T;
export function truthy<T>(value: T): value is Truthy<T> {
  return !!value;
}

const EntityLookUp = {
  quote: '見積書',
  task: '見積書',
  projectTask: 'タスク',
  userinfo: 'ユーザー',
  project: 'プロジェクト',
  businessDivision1: '業務区分①',
  businessDivision2: '業務区分②',
  taskComment: 'メッセージ',
  template: '見積もりに追加',
  client: 'クライアント',
};
export function generateErrorMessage(
  entity: string,
  action: 'PATCH' | 'POST' | 'DELETE',
  success: boolean,
): string {
  let actionMessage = '';

  if (action === 'PATCH') {
    actionMessage = '更新';
  } else if (action === 'POST') {
    actionMessage = '作成';
  } else if (action === 'DELETE') {
    actionMessage = '削除';
  }

  const statusMessage = success ? '成功しました。' : 'に失敗しました。';

  return `${EntityLookUp[entity] || entity}の${actionMessage}${statusMessage}`;
}

export function formatIndex(index: number) {
  if (index < 10) {
    return '0' + index;
  } else {
    return index.toString();
  }
}

export function getSecond(date: Date) {
  const seconds = date.getSeconds();
  const milliseconds = date.getMilliseconds();
  return `${seconds}:${milliseconds}`;
}

export function handleHistory(arg: HistoryParameter) {
  const history = arg.history;
  const file_history = arg.file_history;
  const link_history = arg.link_history;

  let result_arr = [] as HistoryObj[];
  // handle history

  const zip_history = zip(history.slice(1), history.slice(0, zip.length - 1));
  forEach(zip_history, ([before, after]) => {
    forEach(before, (v, i) => {
      if (after && i === 'person_in_charge' && Array.isArray(v) && Array.isArray(after[i])) {
        if (v.length === 0 && after[i].length === 0) {
          return;
        }
      }

      if (after && !i.startsWith('history') && v !== after[i] && i !== 'person_in_charge') {
        result_arr.push({
          key: i,
          change: 'PATCH',
          end: String(after[i]),
          start: v?.toString(),
          date: new Date(after.history_date),
          user: after.history_user_info,
        });
      }
      if (after && !i.startsWith('history') && v !== after[i] && i === 'person_in_charge') {
        if (JSON.stringify(after[i]) === JSON.stringify(v)) {
          return;
        }
        return result_arr.push({
          key: i,
          change: 'PATCH',
          end: JSON.stringify(after[i]),
          start: JSON.stringify(v),
          date: new Date(after.history_date),
          user: after.history_user_info,
        });
      }
    });
  });

  result_arr = [
    ...result_arr,
    ...file_history.map((file) => ({
      key: 'file',
      change: file.type,
      end: file.file_name,
      date: new Date(file.created_at),
      user: file.user,
    })),
  ];
  result_arr = [
    ...result_arr,
    ...link_history.map((link) => ({
      key: 'link',
      change: link.type,
      start: link.old_url,
      end: link.new_url,
      date: new Date(link.created_at),
      user: link.user,
    })),
  ];
  return result_arr;
}

export const checkFileImage = (url: string) => {
  const fileExtensions = ['jpg', 'jpeg', 'png', 'gif'];
  const urlExtension = url.split('.').pop()?.toLocaleLowerCase();
  return urlExtension && fileExtensions.includes(urlExtension);
};

const AvaiableDayArr = ['available_only_holiday', 'work_weekday_day', 'contact_weekday'] as const;
export type AvaiableDayChoice = typeof AvaiableDayArr[number];
type AvailableDayObj = Pick<
  Exclude<UserInfo['attributes'], undefined>,
  typeof AvaiableDayArr[number]
>;

export function getAvaiableDay(obj: AvailableDayObj) {
  if (obj.available_only_holiday) {
    return 'available_only_holiday';
  }
  if (obj.work_weekday_day) {
    return 'work_weekday_day';
  }
  if (obj.contact_weekday) {
    return 'contact_weekday';
  }
  return '';
}

export function getAvaiableObj(arg: typeof AvaiableDayArr[number]) {
  let obj = {} as AvailableDayObj;
  for (const key of AvaiableDayArr) {
    if (key === arg) {
      obj[key] = true;
    } else {
      obj[key] = false;
    }
  }
  return obj;
}

export const getHourWoking = (hour: number) => {
  const language = getLanguage();
  if (language === 'ja') {
    if (hour == 10) {
      return HOUR_10_JA;
    }
    if (hour == 20) {
      return HOUR_20_JA;
    }
    if (hour == 30) {
      return HOUR_30_JA;
    }
    if (hour == 40) {
      return HOUR_40_JA;
    }
    if (hour == 80) {
      return HOUR_80_JA;
    }
    if (hour == 81) {
      return HOUR_81_JA;
    }
  } else {
    if (hour == 10) {
      return HOUR_10;
    }
    if (hour == 20) {
      return HOUR_20;
    }
    if (hour == 30) {
      return HOUR_30;
    }
    if (hour == 40) {
      return HOUR_40;
    }
    if (hour == 80) {
      return HOUR_80;
    }
    if (hour == 81) {
      return HOUR_81;
    }
  }
};

export const removeDuplicate = <T extends number | string>(array: T[]) => {
  return Array.from(new Set(array));
};

//expert detail
export const convertFirstContact = (user: UserInfo) => {
  switch (user.attributes?.primary_contact) {
    case 'FB':
      return user.attributes.facebook;
    case 'LI':
      return user.attributes.linkedin;
    default:
      return user.attributes?.other_social_media;
  }
};

//convert date in message

export function convertDate(dateStr) {
  const inputDate = moment(dateStr);
  const currentDate = moment();

  if (inputDate.isSame(currentDate, 'day')) {
    return inputDate.format('HH:mm');
  } else {
    return inputDate.format(DEFAULT_FORMAT_DATE_TIME);
  }
}

export const handleDowloadFile = async (url: string) => {
  const pathFile = url.split('static/');
  const fileName = url.split('/');
  try {
    const response = await downloadFileAPI.get(pathFile[1]);
    const blob = new Blob([response.data]);

    // Create a Blob object from the binary data
    const url = window.URL.createObjectURL(blob);

    // Create an 'a' element to trigger the file download
    const link = document.createElement('a');
    link.href = url;
    link.download = decodeURIComponent(fileName.pop() ?? '');
    document.body.appendChild(link); // Set the downloaded file name
    link.click();

    // Release the URL and remove the 'a' element
    window.URL.revokeObjectURL(url);
    document.body.removeChild(link);
  } catch (error) {
    console.error('Error downloading file:', error);
  }
};

export const processMessage = async (message: string) => {
  const replacedMessage = await Promise.all(
    message.split(/(<img\s+src=".*?".*?>)/gi).map(async (part) => {
      if (part.includes('<img')) {
        const match = /<img\s+src="(.*?)".*?>/gi.exec(part);
        const pathFile = match && match[1].split('static/');

        if (match && match[1] && pathFile && pathFile[1]) {
          return `<img src="${process.env.REACT_APP_URL_FILE}${pathFile[1]}" alt="image_from_chat"/>`;
        }
        return `<img src="${match && match[1]}" alt="image_from_chat"/>`;
      }

      return part.replace(/<a\s+(?!.*\btarget\b).*?(href=".*?").*?>/gi, '<a target="_blank" $1>');
    }),
  );

  return replacedMessage.join('');
};

export function formatNumber(number, precision = 1) {
  if (number && Number.isInteger(number)) {
    return number.toString();
  } else if (number) {
    return number.toFixed(precision);
  }
}
