import { axiosClient } from '../axiosClient';
import queryString from 'query-string';
import {
  TCreateJobTag,
  TGetJobTagList,
  TUpdateJobTag,
} from 'app/pages/xCare/ContractSection/JobTag/slice/jobTagTypes';

const jobTagApi = {
  getJobTagList: (params: TGetJobTagList) => {
    const url = '/api/tag';
    const query = queryString.stringify(params);
    return axiosClient.get(`${url}?${query}`);
  },
  deleteJobTag: (id: string) => {
    const url = `/api/tag/${id}`;
    return axiosClient.delete(url);
  },
  updateJobTag: (data: TUpdateJobTag) => {
    const { id, ...rest } = data;
    const url = `/api/tag/${id}`;
    return axiosClient.patch(url, rest);
  },
  createJobTag: (data: TCreateJobTag) => {
    const url = '/api/tag';
    return axiosClient.post(url, data);
  },
};

export default jobTagApi;
