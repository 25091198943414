import React from 'react';
import { Choose, Option } from 'types/types';
import Checkbox from 'app/components/common/Checkbox/Checkbox';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';

type Props = {
  option: Option;
  choose: Choose;
  onClick: (arg: string | string[]) => void;
  value: string | string[];
};

export default function BaseOption({ option, choose, onClick, value }: Props) {
  const { t } = useTranslation();
  if (choose === 'single')
    return (
      <div
        onClick={() => onClick(option.value)}
        className={'h-9 py-2 flex px-6 hover:bg-gray-50 items-center'}
      >
        {t(translations.PROJECTS[option.label || '']) || option.label}
        {}
      </div>
    );
  if (Array.isArray(value))
    return (
      <div
        className={'h-9 py-2 flex px-6 items-center hover:bg-gray-50 gap-2 border-bt'}
        onClick={() => {
          if (value.includes(option.value)) {
            onClick(value.filter((i) => i !== option.value));
          } else {
            onClick([...value, option.value].filter(Boolean));
          }
        }}
      >
        <Checkbox checked={value.includes(option.value)} />
        <span className="block truncate overflow-hidden">
          {t(translations.PROJECTS[option.label || '']) || option.label}
        </span>
      </div>
    );
  return <div>This should not render: {JSON.stringify(option)}</div>;
}
