import { LocalStorageContext } from 'app/components/Context/LocalStorage';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { isUndefined } from 'swr/_internal';

export default function useLocaleStorage(
  arg: string,
  initial?: string,
): [string, (arg: string) => void] {
  const { data, setData } = useContext(LocalStorageContext);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (!isUndefined(initial)) {
      setData(initial, arg);
    }
  }, [setData, initial, arg]);
  const value = useMemo(() => data[arg] || '', [data, arg]);
  const setValue = useCallback(
    (value: string) => {
      setData(value, arg);
    },
    [setData, arg],
  );
  return [value, setValue];
}
