import c from 'clsx';
import React, { FC } from 'react';
import '../Icons.scss';
interface Props {
  className?: string;
  size?: number;
  title?: string;
  [x: string]: any;
}

const IconUser: FC<Props> = (props) => {
  const { className, size = 40, title, ...rest } = props;
  return (
    <div className={c('icon-component', `size${size}`, className)} title={title} {...rest}>
      <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
        <rect x="1" y="1" width="38" height="38" rx="19" fill="white" />
        <path
          d="M28 29V27C28 25.9391 27.5786 24.9217 26.8284 24.1716C26.0783 23.4214 25.0609 23 24 23H16C14.9391 23 13.9217 23.4214 13.1716 24.1716C12.4214 24.9217 12 25.9391 12 27V29"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20 19C22.2091 19 24 17.2091 24 15C24 12.7909 22.2091 11 20 11C17.7909 11 16 12.7909 16 15C16 17.2091 17.7909 19 20 19Z"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect x="1" y="1" width="38" height="38" rx="19" stroke="black" strokeWidth="2" />
      </svg>
    </div>
  );
};

export default IconUser;
