import { SelectChangeEvent } from '@mui/material';
import 'app/pages/xCare/ProjectManagement/ProjectTable/ProjectTask.scss';
import c from 'clsx';
import { LANGUAGE_EN, LANGUAGE_JA } from 'locales/i18n';
import React from 'react';
import { useTranslation } from 'react-i18next';
import BaseSelect from '../BaseSelect/BaseSelect';
import './Select.scss';

interface Props {
  className?: string;
  disabled?: boolean;
  placeholder?: string;
  name?: string;
  onChange: (event: SelectChangeEvent<any>) => void;
  value: any;
  children?: React.ReactNode;
  // All other props
  [x: string]: any;
}

const SelectLanguage = ({ className }: Props) => {
  const { i18n } = useTranslation();
  const handleChangeLanguage = (language: string) => {
    i18n.changeLanguage(language);
  };

  const rootClassName = 'w-20 h-8 cursor-text';
  return (
    <BaseSelect
      value={i18n.language}
      className={c(rootClassName, className)}
      choose="single"
      option={[
        { value: LANGUAGE_JA, label: 'JA' },
        { value: LANGUAGE_EN, label: 'EN' },
      ]}
      callback={(e) => handleChangeLanguage(e)}
    />
  );
};

export default SelectLanguage;
