import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import divisionApi from 'app/axios/api/divisionApi';
import projectApi from 'app/axios/api/projectApi';
import quoteApi from 'app/axios/api/quoteApi';
import taskApi from 'app/axios/api/taskApi';
import {
  activeNoti,
  DEFAULT_NOTI_DURATION,
} from 'app/components/Notification/slice/notificationSlice';
import { getUserList } from 'app/pages/xCare/ExpertSection/ExpertListPage/slice/expertListPageSlice';
import { RootState } from 'types';
import { Project } from 'types/project';
import { TGetQuote, TQuote, TQuoteResponse } from 'types/quote';
import { TTask } from 'types/task';
import { UserInfo } from 'types/userInfo';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { ProjectPageDetail } from './projectDetailType';

const initialState: ProjectPageDetail = {
  project: { attributes: {} },
  projectMembers: [],
  listAllTask: [],
  quote: {},
  quoteDetail: {},
  paramsListQuote: {},
  listQuote: [],
  division1: [],
  division2: [],
  changeRoute: false,
  loading: false,
  error: '',
};

type dataType = {
  infoQuote: TQuote;
  listTask?: TTask[];
};

type TAddTask = {
  listTask: TTask[];
  infoQuote: TQuote;
  id: string;
};

export const getProjectDetail = async (projectId: string) => {
  const data = await projectApi.getProjectDetail(projectId);
  return data.data;
};

export const getListQuote = createAsyncThunk(
  'projectDetailPage/getListQuote',
  async (params: TGetQuote, thunkApi) => {
    thunkApi.dispatch(setParamsListQuote(params));
    const data = await quoteApi.getList(params);
    return data?.data?.data || [];
  },
);

export const getAllTask = createAsyncThunk('projectDetailPage/getAllTask', async (_, thunkApi) => {
  const data = await taskApi.getAllTask();
  return data?.data?.data || [];
});

export const addQuote = createAsyncThunk(
  'projectDetailPage/addQuote',
  async (data: dataType, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;
    const { paramsListQuote } = (getState() as RootState).projectDetailPage;

    try {
      const newQuote = await quoteApi.add(data.infoQuote);
      const listDataTask = data.listTask
        ? data.listTask.map((item) => ({ ...item, quote: newQuote.data.data.id }))
        : [];

      return Promise.all(listDataTask.map((task) => taskApi.createTask(task))).then(() => {
        data.infoQuote.project && dispatch(getListQuote(paramsListQuote));
        dispatch(
          activeNoti({
            duration: DEFAULT_NOTI_DURATION,
            message: 'Create quote success!',
            type: 'success',
          }),
        );
      });
    } catch (err) {
      console.log(err);
    }
  },
);

export const getQuoteById = createAsyncThunk(
  'projectDetailPage/getQuoteById',
  async (quoteId: string) => {
    const data = await quoteApi.getId(quoteId);
    return data.data as TQuoteResponse;
  },
);

export const createTask = createAsyncThunk(
  'projectDetailPage/createTask',
  async (payload: TAddTask, thunkAPI) => {
    const { dispatch } = thunkAPI;

    try {
      return Promise.all(
        payload?.listTask?.map((task) => {
          if (task.id) {
            return taskApi.updateTask(task);
          }
          return taskApi.createTask(task);
        }),
      )
        .then(() => {
          dispatch(getQuoteById(payload.id));
          dispatch(
            activeNoti({
              duration: DEFAULT_NOTI_DURATION,
              message: 'Save quote success!',
              type: 'success',
            }),
          );
        })
        .catch((err) =>
          dispatch(
            activeNoti({
              duration: DEFAULT_NOTI_DURATION,
              message: err.message,
              type: 'error',
            }),
          ),
        );
    } catch (err) {
      console.log(err);
    }
  },
);
export const deleteQuote = createAsyncThunk(
  'projectDetailPage/deleteQuote',
  async (data: { id: string; idProject: string }, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;
    const { paramsListQuote } = (getState() as RootState).projectDetailPage;
    await quoteApi
      .delete(data.id)
      .then(() => {
        dispatch(getListQuote(paramsListQuote));
      })
      .catch(() => {});
  },
);

export const getDivision1 = createAsyncThunk('projectDetailPage/getDevision1', async () => {
  try {
    const res = await divisionApi.getBusinessDivision1();
    return res;
  } catch (err) {
    console.log(err);
  }
});

export const getDivision2 = createAsyncThunk('projectDetailPage/getDevision2', async () => {
  try {
    const res = await divisionApi.getBusinessDivision2();
    return res;
  } catch (err) {
    console.log(err);
  }
});

export const getProjectMembers = createAsyncThunk(
  'projectDetailPage/getProjectMembers',
  async () => {
    try {
      const res = await divisionApi.getBusinessDivision2();
      return res;
    } catch (err) {
      console.log(err);
    }
  },
);

export const loadData = createAsyncThunk(
  'projectDetailPage/loadData',
  async (projectId: string) => {
    return Promise.all([getProjectDetail(projectId), getUserList({})]);
  },
);

const slice = createSlice({
  name: 'projectDetailPage',
  initialState,
  reducers: {
    resetChangeRoute: (state) => {
      state.changeRoute = false;
    },
    setParamsListQuote: (state, action: PayloadAction<TGetQuote>) => {
      state.paramsListQuote = action.payload;
    },
    setProject: (state, action: PayloadAction<Project>) => {
      state.project = action.payload;
    },
  },
  extraReducers: (builder) => {
    //loadData
    builder.addCase(loadData.pending, (state: ProjectPageDetail) => {
      state.loading = true;
    });
    builder.addCase(loadData.rejected, (state: ProjectPageDetail, action: any) => {
      state.error = action.error.message;
      state.loading = false;
    });
    builder.addCase(loadData.fulfilled, (state: ProjectPageDetail, action: PayloadAction<any>) => {
      const projectData = action.payload[0].data as Project;
      const userList = action.payload[1].data as Array<UserInfo>;
      const memberList =
        projectData.attributes?.assign
          ?.map(
            (assign) =>
              userList.find((user) => {
                return user.id === assign.expert?.toString();
              })!,
          )
          .filter(Boolean) ?? [];

      state.project = projectData;
      state.projectMembers = memberList;
      state.loading = false;
    });

    //addQuote
    builder.addCase(addQuote.pending, (state: ProjectPageDetail) => {
      state.loading = true;
    });
    builder.addCase(addQuote.rejected, (state: ProjectPageDetail) => {
      state.loading = false;
      state.quote = {};
    });
    builder.addCase(addQuote.fulfilled, (state: ProjectPageDetail) => {
      state.changeRoute = true;
      state.loading = false;
    });

    //getListQuote
    builder.addCase(getListQuote.pending, (state: ProjectPageDetail) => {
      state.loading = true;
    });
    builder.addCase(getListQuote.rejected, (state: ProjectPageDetail) => {
      state.loading = false;
      state.listQuote = [];
    });
    builder.addCase(
      getListQuote.fulfilled,
      (state: ProjectPageDetail, action: PayloadAction<TQuoteResponse[]>) => {
        state.loading = false;
        state.listQuote = action.payload;
      },
    );

    //getQuoteById
    builder.addCase(getQuoteById.pending, (state: ProjectPageDetail) => {
      state.loading = true;
    });
    builder.addCase(getQuoteById.rejected, (state: ProjectPageDetail) => {
      state.loading = false;
      state.quote = {};
    });
    builder.addCase(
      getQuoteById.fulfilled,
      (state: ProjectPageDetail, action: PayloadAction<any>) => {
        state.quoteDetail = action.payload.data;
        state.loading = false;
      },
    );

    //getAllTask
    builder.addCase(getAllTask.pending, (state: ProjectPageDetail) => {
      state.loading = true;
    });
    builder.addCase(getAllTask.rejected, (state: ProjectPageDetail) => {
      state.loading = false;
      state.quote = {};
    });
    builder.addCase(
      getAllTask.fulfilled,
      (state: ProjectPageDetail, action: PayloadAction<any>) => {
        state.listAllTask = action.payload;
        state.loading = false;
      },
    );

    //getDivision
    builder.addCase(
      getDivision1.fulfilled,
      (state: ProjectPageDetail, action: PayloadAction<any>) => {
        state.division1 = action.payload.data.data;
        state.loading = false;
      },
    );
    builder.addCase(
      getDivision2.fulfilled,
      (state: ProjectPageDetail, action: PayloadAction<any>) => {
        state.division2 = action.payload.data.data;
        state.loading = false;
      },
    );
  },
});

export const { actions, reducer: projectDetailPageReducer } = slice;
export const { resetChangeRoute, setParamsListQuote, setProject } = actions;
export default projectDetailPageReducer;
