import { axiosClient } from '../axiosClient';
import { TTask } from 'types/task';

const taskApi = {
  getAllTask: () => {
    const url = '/api/task';
    return axiosClient.get(url);
  },
  createTask: (data: TTask) => {
    const url = '/api/task';
    return axiosClient.post(url, data);
  },
  updateTask: (data: TTask) => {
    const url = `/api/task/${data.id}`;
    return axiosClient.patch(url, data);
  },
  deleteTask: (id: number) => {
    const url = `/api/task/${id}`;
    return axiosClient.delete(url);
  },
};

export default taskApi;
