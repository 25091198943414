import React, { createContext, useCallback, useState } from 'react';
import { MayBeArr } from 'types/types';

export type LocalStorageContextType = {
  data: any;
  setData: (value: any, arg: string) => void;
};
export const LocalStorageContext = createContext({} as LocalStorageContextType);

const getAllItemsFromLocalStorage = () => {
  const keys = Object.keys(localStorage); // get all keys from local storage
  const items = keys.map((key) => {
    return { [key]: localStorage.getItem(key) }; // map each key-value pair to an object
  });
  const result = Object.assign({}, ...items); // merge all objects into one

  return result;
};

interface Props {
  children: MayBeArr<React.ReactNode>;
}

export default function StorageContext({ children }: Props) {
  // Define the initial state
  const [data, setDataProxy] = useState<any>(() => getAllItemsFromLocalStorage());

  // Save the data to local storage every time it changes
  const setData = useCallback(
    (newData, arg: string) => {
      localStorage.setItem(arg, String(newData));
      setDataProxy((data) => ({ ...data, [arg]: newData }));
    },
    [setDataProxy],
  );

  return (
    <LocalStorageContext.Provider value={{ data, setData }}>
      {children}
    </LocalStorageContext.Provider>
  );
}
