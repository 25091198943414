import React from 'react';

interface FileDownloaderProps {
  imagePath: string;
  width?: string;
  height?: string;
  alt?: string;
  className?: string;
  ref?: React.RefObject<HTMLImageElement>;
}

const FileDownloader: React.FC<FileDownloaderProps> = ({
  imagePath,
  width,
  height,
  alt,
  className,
  ref,
}) => {
  const pathFile = imagePath.split('static/').pop();
  const url = `${process.env.REACT_APP_URL_FILE}${pathFile}`;

  return url ? (
    <img
      className={className}
      src={imagePath.startsWith('https') && pathFile ? url : imagePath}
      alt={alt}
      width={width}
      height={height}
      ref={ref}
    />
  ) : (
    <img alt="avatar" />
  );
};

export default FileDownloader;
