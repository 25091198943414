import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import downloadFileAPI from 'app/axios/api/downloadFile';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { ProjectAttachmentState } from './projectAttachmentTypes';

const initialState: ProjectAttachmentState = {
  fileAttachment: '',
  loading: false,
};

export const getFileUrl = createAsyncThunk(
  'projectAttachment/getFileUrl',
  async (body: FormData, thunkAPI) => {
    const data = await downloadFileAPI.createComment(body);
    return data.data;
  },
);

const slice = createSlice({
  name: 'projectAttachment',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getFileUrl.pending, (state, action: PayloadAction<any>) => {
      state.loading = true;
    });
    builder.addCase(getFileUrl.rejected, (state, action: PayloadAction<any>) => {
      state.loading = false;
    });
    builder.addCase(getFileUrl.fulfilled, (state, action: PayloadAction<any>) => {
      state.fileAttachment = action.payload.data?.attributes?.file ?? '';
      state.loading = false;
    });
  },
});

export const { actions, reducer: projectAttachmentReducer } = slice;
export default projectAttachmentReducer;
