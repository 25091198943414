/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from '@reduxjs/toolkit';
import notificationReducer from 'app/components/Notification/slice/notificationSlice';
import authReducer from 'app/pages/Auth/slice/authSlice';
import homePageReducer from 'app/pages/HomePage/slice/homePageSlice';
import resumePageReducer from 'app/pages/ResumePage/slice/resumePageSlice';
import clientListPageReducer from 'app/pages/xCare/ContractSection/ClientListPage/slice/clientListPageSlice';
import clienDetailPageReducer from 'app/pages/xCare/ContractSection/ClientPage/slice/ClientDetailSlice';
import expertDetailEditReducer from 'app/pages/xCare/ExpertSection/ExpertDetailEditPage/slice/expertDetailEditSlice';
import expertDetailReducer from 'app/pages/xCare/ExpertSection/ExpertDetailPage/slice/expertDetailSlice';
import expertListPageReducer from 'app/pages/xCare/ExpertSection/ExpertListPage/slice/expertListPageSlice';
import projectListPageReducer from 'app/pages/xCare/ContractSection/ProjectListPage/slice/projectListPageSlice';

import { InjectedReducersType } from 'utils/types/injector-typings';
import jobTagReducer from 'app/pages/xCare/ContractSection/JobTag/slice/jobTagSlice';
import projectDetailPageReducer from 'app/pages/xCare/ContractSection/ProjectPageDetail/slice/projectDetailSlice';
import ganttChartReducer from 'app/pages/xCare/ProjectManagement/ProjectGantt/slice/ganttChartSlice';
import memberListReducer from 'app/pages/xCare/ProjectManagement/ProjectMember/slice/ProjectMember';
import documentListReducer from 'app/pages/xCare/ProjectManagement/ProjectMemo/slice/ProjectMemoSlice';
import projectAttachmentReducer from 'app/pages/xCare/ProjectManagement/ProjectTable/slice/projectAttachment';
import opManagementPageReducer from 'app/pages/OpManagementPage/slice/opManagementPageSlice';

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export const rootReducers = {
  auth: authReducer,
  notification: notificationReducer,
  homepage: homePageReducer,
  resumePage: resumePageReducer,
  expertListPage: expertListPageReducer,
  expertDetailPage: expertDetailReducer,
  expertDetailEditPage: expertDetailEditReducer,
  clientListPage: clientListPageReducer,
  clientDetailPage: clienDetailPageReducer,
  projectListPage: projectListPageReducer,
  jobTag: jobTagReducer,
  projectDetailPage: projectDetailPageReducer,
  ganttChartPage: ganttChartReducer,
  listMemberPage: memberListReducer,
  documentList: documentListReducer,
  projectAttachment: projectAttachmentReducer,
  opManagementPage: opManagementPageReducer,
};

export function createReducer(injectedReducers: InjectedReducersType = {}) {
  // Initially we don't have any injectedReducers, so returning identity function to avoid the error
  if (Object.keys(injectedReducers).length === 0) {
    return (state) => state;
  } else {
    return combineReducers({
      ...injectedReducers,
    });
  }
}
