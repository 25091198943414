import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import addressApi from 'app/axios/api/addressApi';
import nationalApi from 'app/axios/api/nationalApi';
import skillApi from 'app/axios/api/skillApi';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { OpManagementPageState } from './opManagementPageStateTypes';
import { userInfo } from 'os';
import userInfoApi from 'app/axios/api/userInfoApi';
import projectApi from 'app/axios/api/projectApi';

const initialState: OpManagementPageState = {
  loading: false,
  skillList: [],
  nationalList: [],
  costApplicationList: [],
  costApplicationDetails: {},
};

export const getExpertCostApplicationList = async () => {
  const response = await projectApi.getExpertCostApplication();
  return response?.data;
};

export const getExpertCostApplicationDetails = async (id) => {
  const response = await projectApi.getCostApplicationDetailsByID(id);
  return response?.data;
};

export const loadExpertCostApplication = createAsyncThunk(
  'opManagementPage/loadExpertCostApplication',
  async (_, thunkApi) => {
    try {
      return await Promise.any([getExpertCostApplicationList()]);
    } catch (err) {
      console.log(err);
    }
  },
);

export const getExpertCostAppDetailsByID = createAsyncThunk(
  'projectListPage/getExpertCostAppDetailsByID',
  async (id: string) => {
    const response = await projectApi.getCostApplicationDetailsByID(id);
    return response?.data;
  },
);

const slice = createSlice({
  name: 'opManagementPage',
  initialState,
  reducers: {
    setCostApplicationDetails(state, action: PayloadAction<any>) {
      state.costApplicationDetails = action?.payload;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadExpertCostApplication.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(loadExpertCostApplication.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(loadExpertCostApplication.fulfilled, (state, action: PayloadAction<any>) => {
      state.costApplicationList = action?.payload;
      state.loading = false;
    });
    builder.addCase(getExpertCostAppDetailsByID.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getExpertCostAppDetailsByID.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getExpertCostAppDetailsByID.fulfilled, (state, action: PayloadAction<any>) => {
      state.costApplicationDetails = action?.payload;
      state.loading = false;
    });
  },
});

export const { actions, reducer: opManagementPageReducer } = slice;
export const { setCostApplicationDetails } = actions;
export default opManagementPageReducer;
