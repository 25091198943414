import { createAsyncThunk } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { HomepageState } from './homePageTypes';

// The initial state of the Homepage
const initialState: HomepageState = {};

export const loadData = createAsyncThunk('homepage/loadData', async (_, thunkAPI) => {});

const slice = createSlice({
  name: 'homepage',
  initialState,
  reducers: {},
  extraReducers: (builder) => {},
});

export const { actions, reducer: homePageReducer } = slice;
export default homePageReducer;
