import { axiosClient } from '../axiosClient';

const divisionApi = {
  getBusinessDivision1: () => {
    const url = 'api/businessDivision1';
    return axiosClient.get(url);
  },
  getBusinessDivision2: () => {
    const url = 'api/businessDivision2';
    return axiosClient.get(url);
  },
};

export default divisionApi;
