import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import addressApi from 'app/axios/api/addressApi';
import nationalApi from 'app/axios/api/nationalApi';
import skillApi from 'app/axios/api/skillApi';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { ResumePageState } from './resumePageTypes';
import { userInfo } from 'os';
import userInfoApi from 'app/axios/api/userInfoApi';

const initialState: ResumePageState = {
  loading: false,
  skillList: [],
  nationalList: [],
};

export const getSkillList = async () => {
  const response = await skillApi.getAll();
  return response.data;
};

export const getNationalList = async () => {
  const response = await nationalApi.getAll();
  return response.data;
};

export const getAddressList = async () => {
  const response = await addressApi.getAll();
  return response.data;
};

export const getExpertCareerMasterList = async () => {
  const response = await userInfoApi.getExpertCareerMasterDetails();
  return response.data;
};

export const loadData = createAsyncThunk('resumePage/loadData', async (_, thunkApi) => {
  try {
    return await Promise.all([getSkillList(), getExpertCareerMasterList()]);
  } catch (err) {
    console.log(err);
  }
});

export const loadSkillListData = createAsyncThunk(
  'resumePage/loadSkillListData',
  async (_, thunkApi) => {
    try {
      return await Promise.all([getSkillList()]);
    } catch (err) {
      console.log(err);
    }
  },
);

const slice = createSlice({
  name: 'resumePage',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(loadData.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(loadData.fulfilled, (state, action: PayloadAction<any>) => {
      state.skillList = action?.payload?.[0]?.data;
      state.nationalList = action?.payload?.[1]?.data;
      state.loading = false;
    });
    builder.addCase(loadSkillListData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(loadSkillListData.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(loadSkillListData.fulfilled, (state, action: PayloadAction<any>) => {
      state.skillList = action?.payload?.[0]?.data;
      state.loading = false;
    });
  },
});

export const { actions, reducer: resumePageReducer } = slice;
export default resumePageReducer;
